import React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {space, SpaceProps, width, WidthProps} from 'styled-system';
import {omit, pick} from '@styled-system/props';
import {Colors} from '../Color';
import {Column} from '../Column';
import {ComponentProps} from '../custom-types';
import {FieldError} from '../FieldError';
import {getMainFont} from '../helpers/getMainFont';

type StyledInputProps = {
  icon?: React.ReactNode;
  errorText?: string;
};

const StyledInput = styled.input<StyledInputProps>`
  border: none;
  font-size: inherit;
  font-family: inherit;
  background-color: transparent;
  -webkit-appearance: none;
  color: inherit;
  flex: 1;

  ${(props) =>
    !props.icon &&
    css`
      padding-left: 6px;
    `}

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

type WrapperProps = {
  disabled?: boolean;
  error?: boolean;
  icon?: React.ReactNode;
} & SpaceProps &
  WidthProps;

export const Wrapper = styled.span<WrapperProps>`
  align-items: center;
  background-color: ${Colors.White0};
  border-radius: 8px;
  border: 2px solid ${Colors.Tan70};
  color: ${Colors.Gray100};
  display: inline-flex;
  outline: none;
  font-family: ${({theme}) => getMainFont(theme)};
  flex-direction: row;
  padding: 0 6px 0 ${(props) => (props.icon ? '12px' : '6px')};
  font-size: 16px;
  height: 40px;

  ${(props) =>
    props.disabled &&
    css`
      color: ${Colors.Gray80};
      background-color: ${Colors.Tan10};
      pointer: disabled;

      path {
        fill: ${Colors.Gray80};
      }

      &:focus-within {
        border-color: ${Colors.Tan30} !important;
      }
    `}

  ${(props) =>
    !props.error &&
    css`
      &:hover:enabled {
        background-color: ${Colors.Tan30};
        border-color: ${Colors.Tan30};
      }
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: ${Colors.RedProduct};
    `}

  &:focus-within {
    border-color: ${Colors.Maroon100};
  }

  ${width};
  ${space};
`;

type InputProps = {
  containerStyle?: React.CSSProperties;
  icon?: React.ReactNode;
  errorText?: string;
} & ComponentProps<typeof Wrapper> &
  ComponentProps<typeof StyledInput>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({icon, disabled, error, errorText, containerStyle, ...rest}, ref) => {
    // All styled system props should be forwarded to the wrapper and not the child input.
    const wrapperProps = pick(rest); // styled system props
    const inputProps = omit(rest); // my props

    return (
      <Column
        display="flex"
        justifyContent="start"
        flexDirection="column"
        width="100%"
      >
        <Wrapper
          style={containerStyle}
          icon={icon}
          disabled={disabled}
          error={error}
          {...wrapperProps}
        >
          {icon}
          <StyledInput ref={ref} disabled={disabled} {...inputProps} />
        </Wrapper>
        {error && errorText && <FieldError errorText={errorText} />}
      </Column>
    );
  }
);

Input.defaultProps = {
  type: 'text',
};

Input.displayName = 'Input';
