import React from 'react';
import styled from '@emotion/styled';
import {Colors} from '../Color';
import {ComponentProps} from '../custom-types';

// A container that rotates.
// Combine this with the growing-shrinking circle path
// to create the chasing-tail animation effect.
const RotatingSVG = styled.svg`
  animation: 2s linear infinite both rotate_svg;

  @keyframes rotate_svg {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

// A path that traces the outline of a circle.
// Its length grows and shrinks.
const GrowShrinkCirclePath = styled.circle`
  animation: 1.4s ease-in-out infinite both circle_grow_shrink_animation;
  fill: transparent;
  stroke-dasharray: 285;
  stroke-linecap: round;
  transform-origin: 50% 50%;

  @keyframes circle_grow_shrink_animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
`;

type LoaderProps = ComponentProps<typeof RotatingSVG> & {
  size?: number;
  color?: string;
};

/**
 * A loading animation that features a circle outline spinning,
 * chasing its tail.
 */
export const SpinnerLoader = ({
  color = Colors.Maroon90,
  size = 16,
  ...props
}: LoaderProps): JSX.Element => {
  return (
    <RotatingSVG
      width={size}
      height={size}
      viewBox="0 0 100 100"
      stroke={color}
      {...props}
    >
      <GrowShrinkCirclePath
        strokeOpacity={0.8}
        cx={50}
        cy={50}
        r={45}
        strokeWidth={10}
      />
    </RotatingSVG>
  );
};
