import React, {Component, Fragment} from 'react';
import Header from '../../components/Header.jsx';
import {LoudTitle, DangerText} from '../../components/common/type.jsx';
import {InnerWrapper} from '../../components/layout/index.jsx';
import spacing from '../../components/common/spacing';

/**
 * Selecting the organization to log into.
 */
export default class InternalServerErrorScene extends Component {
  getOrganization (event) {
    event.persist();
    this.delayedCallback(event.target.value);
  }

  render () {
    return (
      <Fragment>
        <Header showSignUp={true} />

        <InnerWrapper>
          <LoudTitle>We encountered an error:</LoudTitle>
          <div style={{marginTop: spacing(1)}}>
            <DangerText>{this.props.error.message}</DangerText>
            {this.props.error.stack && (
              <DangerText><pre>{this.props.error.stack}</pre></DangerText>
            )}
          </div>
        </InnerWrapper>
      </Fragment>
    );
  }
}
