import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';
import {FieldContainer} from './TextInput.jsx';
import {MainFontStack} from './common/type.jsx';
import {Colors} from '@robinpowered/design-system';

function LoginRedirects ({
  state,
  props,
  sso = false,
  password = false
}) {
  return (
    <FieldContainer style={{textAlign: 'center'}}>
      {/* @TODO: support org-less SSO login. */}
      {sso && state.org && (
        <SecondaryLink to={{
          pathname: `/${state.org.slug}/login`,
          search: props.location.search,
          state: {org: state.org, authSettings: state.authSettings}
        }}>
          Use single sign-on instead
        </SecondaryLink>
      )}
      {password && state.org && (
        <ButtonLink to={{
          pathname: `/${state.org.slug}/login/basic`,
          search: props.location.search,
          state: {org: state.org, authSettings: state.authSettings}
        }}>
          Sign in with a email and password
        </ButtonLink>
      )}
      {state.org && (
        <SecondaryLink to={{
          pathname: '/login',
          search: props.location.search
        }}>
          Sign into a different organization
        </SecondaryLink>
      )}
    </FieldContainer>
  );
}

const SecondaryLink = styled(Link)`
  font-family: ${props => props.theme?.fonts?.main ?? MainFontStack};

  font-size: 16px;
  line-height: 20px;
  display: block;
  color: ${Colors.Maroon100};
  font-weight: 500;
  text-decoration: unset;
  margin: 16px auto;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonLink = styled(Link)`
  color: ${Colors.Gray100};
  text-decoration: unset;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-family: ${props => props.theme?.fonts?.main ?? MainFontStack};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  line-height: 1;
  position: relative;
  transition: background-color .2s ease-in-out;
  height: 40px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  padding: 12px 24px;
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Tan30};
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05);
`;

export default LoginRedirects;
