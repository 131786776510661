import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {CacheProvider} from '@emotion/core';
import createCache from '@emotion/cache';
import Layout from '../components/Page.jsx';
import {getHistory} from './history';

import '../../i18n';

const cache = createCache();

const app = document.getElementById('app');
ReactDOM.hydrate((
  <CacheProvider value={cache}>
    <Router history={getHistory()}>
      <Layout />
    </Router>
  </CacheProvider>
), app);
