import {Colors as DesignSystemColors} from "@robinpowered/design-system";

export const State = {
  Default: DesignSystemColors.Gray100,
  Destructive: DesignSystemColors.RedProduct,
  Error: DesignSystemColors.RedProduct,
  Muted: DesignSystemColors.Gray80,
  Success: DesignSystemColors.Green90,
  Warning: DesignSystemColors.Orange100
};

export const Role = {
  ButtonDisabledBackground: DesignSystemColors.Tan20,
  ButtonDisabledBorder: DesignSystemColors.Tan20,
  ButtonDisabledFont: DesignSystemColors.Gray80,
  ButtonPrimaryBackground: DesignSystemColors.Maroon100,
  ButtonPrimaryActive: DesignSystemColors.Maroon120,
  ButtonPrimaryHover: DesignSystemColors.Maroon120,
  DefaultInputBorder: DesignSystemColors.Tan20,
  DefaultBorderColor: DesignSystemColors.Tan20,
  TextInputDisabledBackground: DesignSystemColors.Tan10,
  TextInputDisabledFont: DesignSystemColors.Gray40
};

const Colors = {};
Colors.Role = Role;
Colors.State = State;

export default Colors;
