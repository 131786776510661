import React, {Component, Fragment} from 'react';
import ky from 'ky-universal';
import Header from './Header.jsx';
import Button from './Button.jsx';
import LoginRedirects from './LoginRedirects.jsx';
import {FieldContainer, Input, InputLabel, InputSubtext} from './TextInput.jsx';
import {LoudTitle} from './common/type.jsx';
import {InnerWrapper, OrgContainer, StyledLogo} from './layout/index.jsx';
import ScopeConfirm from './ScopeConfirm.jsx';
import {
  handleSSORedirect
} from '../../services/ssoRedirectHandler';
import ErrorToast from './ErrorToast';

/**
 * Login via username/password.
 */
export default class OrgBasicLogin extends Component {
  constructor (props) {
    super(props);

    const queryParams = new URLSearchParams(props.location.search);
    const queryObject = {};
    queryParams.forEach((value, key) => {
      queryObject[key] = value;
    });

    this.state = {
      org: null,
      loading: false,
      error: null,
      loggedIn: false,
      passwordError: false,
      queryParams,
      queryObject,
      ...props.location.state,
      ...(props.staticContext ? props.staticContext : null)
    };
  }

  onConfirm (scope) {
    ky(`/authorize`, {
      method: 'POST',
      json: {
        ...this.state.queryObject,
        scope
      }
    }).json()
      .then((response) => {
        if (response.url) {
          window.location = response.url;
        }
      }).catch(err => {
        window.Sentry && window.Sentry.captureException(err);
        this.setState({error: err, loading: false});
      });
  }

  login (email, password) {
    this.setState({loading: true});
    ky(`/api/organizations/${this.state.org.id}/auth/users`, {
      method: 'post',
      searchParams: this.state.queryParams,
      headers: {
        authorization: `Basic ${btoa(`${email}:${password}`)}`
      }
    }).json()
      .then((response) => {
        if (!this.state.queryObject.scope && this.state.queryObject.response_type === 'access_token') {
          // Redirect to SSO endpoint if we are asked for an access_token and there are no scopes to confirm.
          handleSSORedirect({
            access_token: response.data.access_token,
            expire_at: response.data.expire_at,
            tenant_id: this.state.org.id
          });
          return;
        }

        this.setState({
          loggedIn: true,
          passwordError: false,
          loading: false
        });
      })
      .catch(err => {

        if (err && err.response.status === 401) {
          this.setState({passwordError: true});
          return;
        } else {
          window.Sentry && window.Sentry.captureException(err);
        }

        this.setState({
          error: err,
          passwordError: false,
          loading: false
        });
      });
  }

  render () {

    const {
      authSettings: {auth_methods: authMethods},
      org
    } = this.state;

    return (
      <Fragment>
        <Header showSignUp={false} />

        <InnerWrapper>
          {org &&
            <OrgContainer>
              <StyledLogo src={org.avatar}
                alt={org.slug}>
              </StyledLogo>
              <LoudTitle style={{textAlign: 'center'}}>
                { this.state.loggedIn ? 'Provide access to your Robin account' : 'Sign in to Robin' }
              </LoudTitle>
            </OrgContainer>
          }

          {this.state.error && <ErrorToast error={this.state.error} />}

          { !this.state.loggedIn && <form className="password-login"
            name="loginForm"
            onSubmit={(event) => event.preventDefault() ||
              this.login(document.querySelector('#user').value, document.querySelector('#pass').value)}
          >

            <FieldContainer>
              <InputLabel>Email address</InputLabel>
              {/* TODO: Validate the email address */}
              <Input
                type="email"
                name="username"
                id="user"
                autoFocus
                required
                aria-invalid="true"
                placeholder="you@yourcompany.com"
              />
            </FieldContainer>

            <FieldContainer error={this.state.passwordError}>
              <InputLabel error={this.state.passwordError}>Password</InputLabel>
              <Input
                type="password"
                name="password"
                id="pass"
                autoComplete="off"
                required
                aria-invalid="true"
                error={this.state.passwordError}
                placeholder="Password"
              />
              {this.state.passwordError && (
                <InputSubtext error={true}>Incorrect email or password.</InputSubtext>
              )}
            </FieldContainer>

            <FieldContainer>
              <Button primary type="submit" style={{width: '100%'}}
                value="Sign in" />
            </FieldContainer>

            <LoginRedirects
              sso={authMethods.length > 1}
              state={this.state}
              props={this.props}
            />
          </form> }
          { (this.state.loggedIn && this.state.queryObject.scope) && <ScopeConfirm
            org={org}
            scopes={this.state.queryObject.scope}
            onConfirm={this.onConfirm.bind(this)}
          /> }
        </InnerWrapper>
      </Fragment>
    );
  }
}
