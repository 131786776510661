"use strict";
exports.__esModule = true;
exports.SvgMinorWarningSolid = void 0;
var React = require("react");
var SvgWrapper_1 = require("./../SvgWrapper");
/**
 * @deprecated This icon is deprecated. Please use a non-v2 alternative.
 */
var SvgMinorWarningSolid = function (props) { return (<SvgWrapper_1.default {...props} aspectRatio={1}>
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM13.4286 12C13.4286 12.789 12.789 13.4286 12 13.4286C11.211 13.4286 10.5714 12.789 10.5714 12L10.5714 7.71429C10.5714 6.92531 11.211 6.28571 12 6.28571C12.789 6.28571 13.4286 6.92531 13.4286 7.71429L13.4286 12ZM12 14.8571C11.211 14.8571 10.5714 15.4967 10.5714 16.2857C10.5714 17.0747 11.211 17.7143 12 17.7143C12.789 17.7143 13.4286 17.0747 13.4286 16.2857C13.4286 15.4967 12.789 14.8571 12 14.8571Z" fill={props.color || "#10293C"}/>
      </g>
    </svg>
  </SvgWrapper_1.default>); };
exports.SvgMinorWarningSolid = SvgMinorWarningSolid;
exports["default"] = exports.SvgMinorWarningSolid;
