import React, { Component, Fragment } from "react";
import queryString from "query-string";
import ky from "ky-universal";
import Header from "./Header.jsx";
import Button from "./Button.jsx";
import LoginRedirects from "./LoginRedirects.jsx";
import { GoogleLogo, MicrosoftLogo } from "./Logos.jsx";
import { InnerWrapper, StyledLogo, OrgContainer } from "./layout/index.jsx";
import {
  getSAMLConfig,
  getGoogleConfig,
  getOffice365Config,
  getOffice365GraphConfig,
  getSAMLMethods,
  getClient,
} from "../../services/sso";
import { Heading02 } from "@robinpowered/design-system";
import getBaseUrl from "../../utils/getBaseUrl";
import {
  handleSSORedirect,
  clearSSOState,
  refreshSSORedirectUrl,
  setTenantId,
} from "../../services/ssoRedirectHandler";
import spacing from "./common/spacing";

const SAML_METHODS = getSAMLMethods();

/**
 * Login page with org context that shows various options (SSO, password) for logging in.
 */
export default class OrgLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStrict: true,
      loading: true,
      ...props.location.state,
      ...(props.staticContext ? props.staticContext : null),
    };
  }

  async componentDidMount() {
    if (this.state.org && this.state.org.id) {
      await setTenantId(this.state.org.id);
    }
    if (this.state.authSettings && this.state.org) {
      this.setState({ loading: false });
      return;
    }

    ky.get(`/api/organizations/${this.state.org.id}/auth`)
      .json()
      .then(({ data }) => this.setState({ error: false, authSettings: data }))
      .catch((err) => {
        window.Sentry && window.Sentry.captureException(err);
        this.setState({ error: true, authSettings: null });
      })
      .finally(() => this.setState({ loading: false }));
  }

  getDomains(authMethods) {
    return authMethods
      .filter(
        ({ type }) =>
          type === "google" || type === "office_365" || type === "graph"
      )
      .map(({ uri }) => uri)
      .join(", ");
  }

  async openPopup(configuration) {
    const parsed = queryString.parse(window.location.search);
    const ssoClient = getClient(
      configuration,
      !window.opener && !parsed.inline
    );

    await refreshSSORedirectUrl();

    return ssoClient
      .getToken({
        token: configuration.token,
        client_secret: configuration.client_secret,
        relay_state: configuration.relay_state,
        RelayState: configuration.RelayState,
        response_type: configuration.response_type,
        redirect_uri: configuration.redirect_uri,
      })
      .then((response) => {
        if (this.state.provider === "saml") {
          // Map SAML's expires to expire_at.
          response = {
            ...response,
            expire_at: response.expires,
          };
        }
        // GetToken will resolve an error
        if (response.meta && response.meta.status_code >= 400) {
          ssoClient.wipeTokens();
          throw new Error(response.message);
        }

        if (response.data) {
          return response.data;
        }

        // SAML by default doesn't have nested `data`.
        return response;
      })
      .then(({ access_token, expire_at }) =>
        handleSSORedirect(
          { access_token, expire_at, tenant_id: this.state.org.id },
          ssoClient
        )
      )
      .catch(async (err) => {
        window.Sentry && window.Sentry.captureException(err);

        await clearSSOState();
        throw err;
      });
  }

  async loginWithGoogle() {
    return await this.openPopup({
      ...getGoogleConfig(getBaseUrl()),
    });
  }

  async loginWithOffice365() {
    return await this.openPopup({
      ...getOffice365Config(getBaseUrl()),
    });
  }

  async loginWithOffice365Graph() {
    return await this.openPopup({
      ...getOffice365GraphConfig(getBaseUrl()),
    });
  }

  async loginWithSaml() {
    const parsed = queryString.parse(window.location.search);

    return await this.openPopup({
      ...getSAMLConfig(getBaseUrl(), this.state.org.id, parsed.response_type),
    });
  }

  render() {
    if (this.state.loading) {
      return <div>loading</div>;
    }

    const {
      authSettings: { auth_methods: authMethods },
      org,
    } = this.state;

    return (
      <Fragment>
        <Header showSignUp={false} />

        <InnerWrapper>
          <form className="centered">
            {org && (
              <OrgContainer>
                <StyledLogo src={org.avatar} alt={org.slug}></StyledLogo>
                <Heading02 style={{ textAlign: "center" }}>
                  Log in to your account
                </Heading02>
              </OrgContainer>
            )}

            <div>
              {authMethods.find(({ type }) => type === "google") && (
                <Button
                  style={{ display: "block", marginBottom: spacing(2) }}
                  type="button"
                  onClick={() => this.loginWithGoogle()}
                >
                  <GoogleLogo />
                  <span style={{ marginLeft: spacing(1) }}>
                    Sign in with Google
                  </span>
                </Button>
              )}
              {authMethods.find(
                ({ type }) => type === "office_365" || type === "graph"
              ) && (
                <Button
                  style={{ display: "block", marginBottom: spacing(2) }}
                  type="button"
                  onClick={() =>
                    authMethods.some(({ type }) => type === "graph")
                      ? this.loginWithOffice365Graph()
                      : this.loginWithOffice365()
                  }
                >
                  <MicrosoftLogo />
                  <span style={{ marginLeft: spacing(1) }}>
                    Sign in with Office 365
                  </span>
                </Button>
              )}
              {(() => {
                const authMethod = authMethods.find(({type}) => SAML_METHODS.includes(type));

                if (!authMethod) {
                  return null;
                }

                return (
                  <Button
                    style={{display: 'block', marginBottom: spacing(2)}}
                    type="button"
                    onClick={() => this.loginWithSaml()}
                  >
                    <span>Sign in with {({
                      okta: 'Okta',
                      rippling: 'Rippling',
                      onelogin: 'OneLogin '
                    })[authMethod.type] || 'Single Sign-On'}</span>
                  </Button>
                );
              })()}
            </div>

            <LoginRedirects
              password={authMethods.find(({ type }) => type === "password")}
              state={this.state}
              props={this.props}
            />
          </form>
        </InnerWrapper>
      </Fragment>
    );
  }
}
