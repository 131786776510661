import React from 'react';
import {Colors} from '../Color';
import {Row} from '../Row';
import {Body06} from '../Typography/Body';
import MinorWarningSolid from '@robinpowered/icons/v2/MinorWarningSolid';

/**
 * Used with form validation to give more information to the user.
 */
export const FieldError = ({errorText}: {errorText: string}): JSX.Element => {
  return (
    <Row aria-live="polite" display="flex" alignItems="center" mt="8px">
      <MinorWarningSolid
        aria-label="field error"
        size={14}
        color={Colors.RedProduct}
      />
      <Body06
        color={Colors.RedProduct}
        style={{
          lineHeight: 1,
          marginLeft: '4px',
        }}
      >
        {errorText}
      </Body06>
    </Row>
  );
};
