import React, {FunctionComponent, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {space, SpaceProps} from 'styled-system';

import {Skeleton} from '../Skeleton';

type ImgProps = {
  src: string;
  alt: string;
  width: string;
  height: string;
  radius?: string;
} & SpaceProps;

const StyledImage = styled('img')<ImgProps>`
  object-fit: cover;
  ${({radius}) => ({borderRadius: radius})};
  ${space}
`;

export const Img: FunctionComponent<ImgProps> = ({
  src,
  alt,
  width,
  height,
  radius = '4px',
  ...props
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setLoaded(true);
    img.src = src;
    return () => {
      img.onload = () => null;
    };
  }, [src]);

  if (!loaded) {
    return <Skeleton width={width} height={height} />;
  }
  return (
    <StyledImage
      src={src}
      alt={alt}
      width={width}
      height={height}
      radius={radius}
      {...props}
    />
  );
};
