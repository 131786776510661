import styled from '@emotion/styled';
import {getMainFont} from '../helpers/getMainFont';
import {Colors} from '../Color';
import {CoreFontSize, CoreFontWeight} from '../Typography/constants';

export const Link = styled.a`
  color: ${Colors.Maroon100};
  cursor: pointer;
  font-family: ${({theme}) => getMainFont(theme)};
  font-size: ${CoreFontSize.s14};
  font-weight: ${CoreFontWeight.Medium};
  line-height: 16px;
  text-decoration: none;
  &:hover {
    color: ${Colors.Maroon110};
    text-decoration: underline;
  }
`;
