export const CoreFontSize = {
  s26: '26px',
  s22: '22px',
  s20: '20px',
  s18: '18px',
  s16: '16px',
  s14: '14px',
  s12: '12px',
} as const;

export const CoreFontWeight = {
  Regular: 400,
  Medium: 500,
  Bold: 600,
} as const;

export const BrownFontStack =
  'Brown,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'; // eslint-disable-line max-len
