import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from './locales/en';

export const i18n = i18next.use(initReactI18next);

i18n.init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  ns: ['common'],
  defaultNS: 'common',
  resources: {en},
});
