import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {getMainFont} from '../../helpers/getMainFont';
import {
  compose,
  color,
  ColorProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';
import {Colors} from '../../Color';
import {CoreFontSize, CoreFontWeight} from '../constants';

type HeadingProps = ColorProps & SpaceProps & LayoutProps & FlexboxProps;
type CoreStyleProps = {
  color: string;
  fontFamily: string;
  fontWeight: number;
  fontFeatureSettings: string;
  letterSpacing: string;
  margin: number;
};
type HeadingStyleProps = {
  fontSize: string;
  lineHeight: string | number;
};

const HeadingStyledSystem = compose(color, space, layout, flexbox);

const BaseHeadingStyles = (theme: Theme): CoreStyleProps => ({
  color: Colors.Gray100,
  fontFamily: getMainFont(theme),
  fontWeight: CoreFontWeight.Medium,
  fontFeatureSettings: "'tnum' on, 'lnum' on, 'ss02' on",
  letterSpacing: '0.01em',
  margin: 0,
});

export const Heading01Styles: HeadingStyleProps = {
  fontSize: CoreFontSize.s26,
  lineHeight: '36px',
};
/**
 * Component used for layout headings.
 *
 * color: N700; fontSize: 26px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Heading01 = styled.h1<HeadingProps>(
  ({theme}) => BaseHeadingStyles(theme),
  Heading01Styles,
  HeadingStyledSystem
);

export const Heading02Styles: HeadingStyleProps = {
  fontSize: CoreFontSize.s22,
  lineHeight: '28px',
};
/**
 * Component used for container and section headings.
 *
 * color: N700; fontSize: 22px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Heading02 = styled.h2<HeadingProps>(
  ({theme}) => BaseHeadingStyles(theme),
  Heading02Styles,
  HeadingStyledSystem
);

export const Heading03Styles: HeadingStyleProps = {
  fontSize: CoreFontSize.s20,
  lineHeight: '22px',
};
/**
 * Component used for container and section headings.
 *
 * color: N700; fontSize: 20px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Heading03 = styled.h3<HeadingProps>(
  ({theme}) => BaseHeadingStyles(theme),
  Heading03Styles,
  HeadingStyledSystem
);

export const Heading04Styles: HeadingStyleProps = {
  fontSize: CoreFontSize.s18,
  lineHeight: 1.2,
};
/**
 * Component used for container and section headings.
 *
 * color: N700; fontSize: 18px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Heading04 = styled.h4<HeadingProps>(
  ({theme}) => BaseHeadingStyles(theme),
  Heading04Styles,
  HeadingStyledSystem
);

export const Heading05Styles: HeadingStyleProps = {
  fontSize: CoreFontSize.s16,
  lineHeight: '20px',
};
/**
 * Component used for container and section headings.
 *
 * color: N700; fontSize: 16px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Heading05 = styled.h5<HeadingProps>(
  ({theme}) => BaseHeadingStyles(theme),
  Heading05Styles,
  HeadingStyledSystem
);

export const Heading06Styles: HeadingStyleProps = {
  fontSize: CoreFontSize.s14,
  lineHeight: '16px',
};
/**
 * Component used for container and section headings.
 *
 * color: N700; fontSize: 14px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Heading06 = styled.h6<HeadingProps>(
  ({theme}) => BaseHeadingStyles(theme),
  Heading06Styles,
  HeadingStyledSystem
);

export const Heading07Styles: HeadingStyleProps = {
  fontSize: CoreFontSize.s12,
  lineHeight: '16px',
};
/**
 * Component used for container and section headings.
 *
 * color: N700; fontSize: 12px; fontWeight: 500
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Heading07 = styled.h6<HeadingProps>(
  ({theme}) => BaseHeadingStyles(theme),
  Heading07Styles,
  HeadingStyledSystem
);
