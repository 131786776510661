"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
var React = require("react");
function SVGTemplate(_a) {
    var width = _a.width, height = _a.height, size = _a.size, children = _a.children, _b = _a.aspectRatio, aspectRatio = _b === void 0 ? 1 : _b, rest = __rest(_a, ["width", "height", "size", "children", "aspectRatio"]);
    if (size) {
        width = size * aspectRatio;
        height = size;
    }
    return React.cloneElement(children, __assign(__assign({}, rest), { width: width, height: height }));
}
exports["default"] = SVGTemplate;
