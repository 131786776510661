import React, { Component, Fragment } from "react";
import Header from "./Header.jsx";
import { Title, DangerText, LoudTitle } from "./common/type.jsx";
import spacing from "./common/spacing";
import { InnerWrapper } from "./layout/index.jsx";
import {
  getSAMLConfig,
  getGoogleConfig,
  getOffice365Config,
  getOffice365GraphConfig,
  getClient,
} from "../../services/sso";
import getBaseUrl from "../../utils/getBaseUrl";
import {
  clearSSOState,
  handleSSORedirect,
} from "../../services/ssoRedirectHandler";

/**
 * Login page with org context that shows various options (SSO, password) for logging in.
 */
export default class OrgLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStrict: true,
      loading: true,
      error: null,
      ...props.location.state,
      ...(props.staticContext ? props.staticContext : null),
    };
  }

  componentDidMount() {
    if (this.state.provider) {
      const configuration = this.getParamsForType(this.state.provider);
      const ssoClient = getClient(configuration);

      Promise.resolve()
        .then(() => ssoClient.callback())
        .catch((err) => {
          if (ssoClient.codeExchange) {
            const promise = ssoClient.codeExchange;

            delete ssoClient.codeExchange;

            return promise;
          }

          throw err;
        })
        .then((response) => {
          if (this.state.provider === "saml") {
            // Map SAML's expires to expire_at.
            response = {
              ...response,
              expire_at: response.expires,
            };
          }

          // JSO will `resolve` an exceptional failure code, so we have to handle that.
          if (response.meta && response.meta.status_code >= 400) {
            throw new Error(response.message);
          }

          if (response.data) {
            return response.data;
          }

          // SAML by default doesn't have nested `data`.
          return response;
        })
        .then(({ access_token, expire_at }) =>
          handleSSORedirect({ access_token, expire_at }, ssoClient)
        )
        .catch(async (err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          window.Sentry && window.Sentry.captureException(err);

          await clearSSOState();
          ssoClient.wipeTokens();

          this.setState({ error: err });
        });
    }
  }

  getParamsForType(type) {
    switch (type) {
      case "google":
        return getGoogleConfig(getBaseUrl());
      case "office365":
        return getOffice365Config(getBaseUrl());
      case "graph":
        return getOffice365GraphConfig(getBaseUrl());
      case "saml":
        return getSAMLConfig(getBaseUrl());
      default:
        throw new Error(`Unsupported type ${type}`);
    }
  }

  render() {
    const { error } = this.state;

    return (
      <Fragment>
        <Header showSignUp={false} />
        <InnerWrapper>
          {!error && (
            <Title style={{ textAlign: "center", marginTop: spacing(4) }}>
              Logging you in...
            </Title>
          )}
          {error && (
            <>
              <LoudTitle>We encountered an error:</LoudTitle>
              <div style={{ marginTop: spacing(2) }}>
                <DangerText>{error.message}</DangerText>
              </div>
            </>
          )}
        </InnerWrapper>
      </Fragment>
    );
  }
}
