import styled from '@emotion/styled';
import {Size} from './common/type.jsx';
import spacing from './common/spacing';
import {State} from './common/colors';
import {Weight, MainFontStack} from './common/type.jsx';
import {Colors} from '@robinpowered/design-system';

export const FieldContainer = styled.div`
  display: block;
  max-width: 480px;
  width: 100%;
  margin: ${spacing(2)} auto;
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: ${spacing(1)};
  color: ${({error}) => error ? State.Error : Colors.Gray80};
  font-family: ${props => props.theme?.fonts?.main ?? MainFontStack};
  font-size: ${Size.Small}px;
  font-weight: ${Weight.Normal};
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 2px solid ${({error}) => error ? State.Error : 'transparent'};
  box-shadow: none;
  height: 40px;
  font-size: ${Size.Default}px;
  border-radius: 8px;
  padding: ${spacing(1)} 12px;
  background-color: ${Colors.Tan10};

  &::placeholder {
    color: ${Colors.Gray40};
  }
`;

export const InputSubtext = styled.span`
  display: block;
  margin-top: ${spacing(1)};
  color: ${({error}) => error ? State.Error : Colors.Gray80};
  font-family: ${props => props.theme?.fonts?.main ?? MainFontStack};
  font-size: ${Size.Small}px;
  font-weight: ${Weight.Medium};
`;
