import {Colors} from './Color';
import {BrownFontStack} from './Typography/constants';

const theme = {
  colors: Colors,
  fontSizes: [10, 12, 14, 16, 18, 20, 22, 26],
  fonts: {
    main: BrownFontStack,
  },
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64],
  shadows: [
    'none',
    '0px 1px 3px rgba(0, 0, 0, 0.08)',
    '0px 1px 12px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.15)',
    '0px 1px 12px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.1)',
    '0px 1px 20px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.04)',
  ],
};

export default theme;
