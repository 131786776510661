import React, {Component} from 'react';
import {ToastProvider} from 'react-toast-notifications';
import OrgSelect from './OrgSelect.jsx';
import OrgLogin from './OrgLogin.jsx';
import OrgBasicLogin from './OrgBasicLogin.jsx';
import InlineRedirect from './InlineRedirect.jsx';
import NotFound from './NotFound.jsx';
import InternalServerErrorScene from '../scenes/errors/500.jsx';
import BadRequestErrorScene from '../scenes/errors/400.jsx';
import {Switch, Route} from 'react-router-dom';
import {withRouter} from 'react-router';
import localforage from 'localforage';
import queryString from 'query-string';
import styled from '@emotion/styled';
import isTrustedRedirectUrl from '../../services/trustedRedirectUrls';
import {ApolloProvider} from '@apollo/client';
import {client} from '../../config/apollo.js';
import {Colors, RKTheme} from '@robinpowered/design-system';

// eslint-disable-next-line react/display-name
const withStaticContext = (Component, context) => (props) => (
  <Component {...props} staticContext={context} />
);

const WrapperContainer = styled.div`
  min-height: 100%;
  height: auto;
  width: 100%;
  position: absolute;
`;

const Container = styled.div`
  min-height: 100%;
  height: auto;
  width: 100%;
  position: absolute;
`;

const MainLoginContainer = styled.div`
  background: ${Colors.Tan10};
  height: 100vh;
`;

export const LayoutWrapper = ({children}) => (
  <WrapperContainer>
    <Container>
      <MainLoginContainer className="main-login">
        {children}
      </MainLoginContainer>
    </Container>
  </WrapperContainer>
);

class Layout extends Component {
  constructor (props) {
    super(props);

    this.state = {
      error: null,
      clientError: null,
      ...this.props.staticContext
    };
  }

  componentDidMount () {
    localforage.config({
      name: 'oauth',
      storeName: 'oauth'
    });

    const parsed = queryString.parse(window.location.search);

    if (parsed.redirect_uri && !isTrustedRedirectUrl(parsed.redirect_uri)) {
      this.setState({
        clientError: `Invalid redirect URI: ${parsed.redirect_uri}`
      });
    }
  }

  render () {
    const {error, clientError, ...restStaticContext} = this.state;

    return (
      <ApolloProvider client={client}>
        <RKTheme>
          <ToastProvider>
            <LayoutWrapper>
              {!error && !clientError && (
                <Switch>
                  <Route
                    path="/login"
                    exact
                    component={withStaticContext(OrgSelect, restStaticContext)}
                  />
                  <Route
                    path="/inline-redirect/:provider"
                    exact
                    component={withStaticContext(
                      InlineRedirect,
                      restStaticContext
                    )}
                  />
                  <Route
                    path="/:orgId/login"
                    exact
                    component={withStaticContext(OrgLogin, restStaticContext)}
                  />
                  <Route
                    path="/:orgId/login/basic"
                    exact
                    component={withStaticContext(
                      OrgBasicLogin,
                      restStaticContext
                    )}
                  />
                  <Route
                    component={withStaticContext(NotFound, restStaticContext)}
                  />
                </Switch>
              )}
              {clientError && <BadRequestErrorScene error={clientError} />}
              {error && <InternalServerErrorScene error={error} />}
            </LayoutWrapper>
          </ToastProvider>
        </RKTheme>
      </ApolloProvider>
    );
  }
}

function withContext (WrappedComponent) {
  return class WithContext extends Component {
    render () {
      if (this.props.staticContext) {
        return <WrappedComponent {...this.props} />;
      }
      return <WrappedComponent {...this.props} staticContext={window.__APP_CONTEXT__ || {}} />;
    }
  };
}

export default withRouter(withContext(Layout));
