/* eslint-env browser */
import Url from "url-parse";
import localforage from "localforage";
import queryString from "query-string";
import ky from "ky-universal";

const REDIRECT_KEY = "sso_redirect_uri";
const STATE_KEY = "sso_state";
const RESPONSE_TYPE_KEY = "response_type";
const SCOPE_KEY = "scope";
const CLIENT_ID_KEY = "client_id";
const TENANT_ID_KEY = "sso_tenant_id";

export async function refreshSSORedirectUrl() {
  const parsed = queryString.parse(window.location.search);

  if (parsed.redirect_uri) {
    await localforage.setItem(REDIRECT_KEY, parsed.redirect_uri);
  } else if (parsed.redirect_url) {
    await localforage.setItem(REDIRECT_KEY, parsed.redirect_url);
  } else {
    await clearSSORedirectUrl();
  }

  if (parsed.state) {
    await localforage.setItem(STATE_KEY, parsed.state);
  } else {
    await clearSSORedirectState();
  }

  if (parsed.response_type) {
    await localforage.setItem(RESPONSE_TYPE_KEY, parsed.response_type);
  } else {
    await clearSSOResponseTypeState();
  }

  if (parsed.scope) {
    await localforage.setItem(SCOPE_KEY, parsed.scope);
  } else {
    await clearSSOScopeState();
  }

  if (parsed.client_id) {
    await localforage.setItem(CLIENT_ID_KEY, parsed.client_id);
  } else {
    await clearSSOClientIDState();
  }
}

async function clearSSORedirectUrl() {
  await localforage.removeItem(REDIRECT_KEY);
}

async function clearSSORedirectState() {
  await localforage.removeItem(STATE_KEY);
}

async function clearSSOResponseTypeState() {
  await localforage.removeItem(RESPONSE_TYPE_KEY);
}

async function clearSSOScopeState() {
  await localforage.removeItem(SCOPE_KEY);
}

async function clearSSOClientIDState() {
  await localforage.removeItem(CLIENT_ID_KEY);
}

async function clearSSOTenantId() {
  await localforage.removeItem(TENANT_ID_KEY);
}

export async function clearSSOState() {
  await clearSSORedirectUrl();
  await clearSSORedirectState();
  await clearSSOResponseTypeState();
  await clearSSOScopeState();
  await clearSSOClientIDState();
  await clearSSOTenantId();
}

export async function setTenantId(tenantId) {
  if (tenantId) {
    await localforage.setItem(TENANT_ID_KEY, tenantId);
  }
}

function getSSORedirectUrl() {
  return Promise.all([
    localforage.getItem(REDIRECT_KEY),
    localforage.getItem(STATE_KEY),
    localforage.getItem(TENANT_ID_KEY),
  ]).then(([redirectUri, redirectState, tenantId]) => {
    if (redirectUri) {
      return { redirectUri, state: redirectState, tenantId };
    }

    // If no redirect URI available, _try_ to parse query params still.
    const parsed = queryString.parse(window.location.search);

    return {
      redirectUri: parsed.redirect_uri,
      state: redirectState || parsed.state,
      tenantId,
    };
  });
}

export function handleSSORedirect(
  { access_token, expire_at, tenant_id },
  ssoClient
) {
  return localforage
    .getItem(RESPONSE_TYPE_KEY)
    .then((responseType) => {
      if (responseType === "access_token") {
        return getSSORedirectUrl().then(({ redirectUri, state, tenantId }) => {
          const url = new Url(redirectUri, true);

          url.set("query", {
            ...url.query,
            ...(state ? { state } : null),
            ...(tenantId || tenant_id
              ? { tenant_id: tenantId || tenant_id }
              : null),
            ...(access_token ? { access_token } : null),
            ...(expire_at ? { expire_at } : null),
          });

          return url.toString();
        });
      }

      // Response type is code:
      return Promise.all([
        localforage.getItem(SCOPE_KEY),
        localforage.getItem(CLIENT_ID_KEY),
        localforage.getItem(STATE_KEY),
        getSSORedirectUrl(),
      ])
        .then(([scope, clientId, state, { redirectUri }]) =>
          ky(`/authorize`, {
            method: "POST",
            json: {
              scope: scope,
              state,
              response_type: "code",
              client_id: clientId,
              redirect_uri: redirectUri,
            },
          }).json()
        )
        .then((response) => response.url);
    })
    .then(async (url) => {
      // Remove any trace of SSO URL.
      await clearSSOState();

      if (ssoClient) {
        ssoClient.wipeTokens();
      }

      window.location = url;
    });
}
