import styled from "@emotion/styled";
import { Colors } from "@robinpowered/design-system";
import spacing from "../common/spacing";

const CoverOverlay = styled.div`
  min-height: 100%;
  height: auto;
  width: 100%;
  position: absolute;
`;

const InnerWrapper = styled.section`
  border-radius: 16px;
  padding: ${spacing(4)};
  background-color: ${Colors.White0};
  max-width: 550px;
  border: 1px solid ${Colors.Tan20};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 20px auto 0;
  position: relative;
`;

const StyledLogo = styled.img`
  width: ${spacing(12)};
  height: ${spacing(12)};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing(2)};
  object-fit: container;
  display: block;
  border-radius: 8px;
`;

const OrgContainer = styled.div`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};
`;

export { OrgContainer, StyledLogo, InnerWrapper, CoverOverlay };
