import React, { Fragment } from "react";
import {
  Heading02,
  Heading04,
  Body05,
  Button,
  Img,
  Colors,
  Link
} from '@robinpowered/design-system';
import { getHistory } from "../client/history";
import { InnerWrapper } from "./layout/index.jsx";
import styled from "@emotion/styled";
import Header from "./Header.jsx";

/**
 * Selecting the organization to log into.
 */

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const buttonStyles = {
  color: Colors.N300,
  width: '100%',
  justifyContent: 'flex-start',
  marginBottom: '2px',
  border: 'none'
};

const NoAvatar = styled.span`
  height: 48px;
  width: 48px;
`;

const Footer = styled.div`
  text-align: center;
  margin-top: 24px;
`;

export const OrgSelector = ({ userOrgs, props, setShowOrgSelect }) => {
  const onRedirect = (org) => {
    getHistory().push(
      // `props.location.search` holds all of the query params.
      `/${org.slug}/login${props.location.search}`,
      { org: org }
    );
  };
  return (
    <Fragment>
      <Header showSignUp={true} />

      <InnerWrapper>
        <TitleContainer>
          <Heading02>Choose a primary organization</Heading02>
          <Body05 mt="8px">You may always switch organizations later</Body05>
        </TitleContainer>
        <ListContainer>
          {userOrgs.map((org, index) => {
            return (
              <Button
                primary
                type="submit"
                variant="secondary"
                style={buttonStyles}
                key={index}
                onClick={() => onRedirect(org)}
              >
                {org.avatar ?
                  <Img src={org.avatar} alt={org.slug} width={48} height={48} />
                  : <NoAvatar />
                }
                <Heading04 ml="16px">{org.name}</Heading04>
              </Button>
            );
          })}
        </ListContainer>
        <Footer>
          <Link style={{fontSize: '16px'}} onClick={() => setShowOrgSelect(false)}>
            Go back
          </Link>
        </Footer>
      </InnerWrapper>
    </Fragment>
  );
};

const ListContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 24px;
  padding: 4px;
`;
