import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { FieldContainer } from "./TextInput.jsx";
import { InnerWrapper } from "./layout/index.jsx";
import { gql, useLazyQuery } from "@apollo/client";
import Header from "./Header.jsx";
import { OrgSelector } from "./OrgSelector.jsx";
import { isValidEmail } from "../../utils/string.js";
import {
  Link,
  Button,
  Input,
  Body05,
  Heading02,
  Flex,
} from "@robinpowered/design-system";
import { getHistory } from "../client/history";
import { useTranslation } from "react-i18next";
import { refreshSSORedirectUrl } from "../../services/ssoRedirectHandler";

const ORG_BY_USER_EMAIL_QUERY = gql`
  query GetOrganizationsByUserEmail($email: String!) {
    getOrganizationsByUserEmail(email: $email) {
      id
      slug
      avatar
      name
    }
  }
`;

export const EmailLogin = ({ email, props, showEmailLoginScreen }) => {
  const { t } = useTranslation("common");

  const [errorText, setErrorText] = useState(false);
  const [userEmail, setUserEmail] = useState(email);
  const [showOrgSelect, setShowOrgSelect] = useState(false);

  const [getOrgByEmail, { data, loading, error }] = useLazyQuery(
    ORG_BY_USER_EMAIL_QUERY
  );

  // Make sure we save the SSO URL properly.
  useEffect(() => {
    const fetchSSORedirectUrl = async () => {
      await refreshSSORedirectUrl();
    };

    fetchSSORedirectUrl();
  }, []);

  const handleEmailInput = (e) => {
    setUserEmail(e.target.value);
  };

  const orgData = useMemo(() => {
    return data ? data.getOrganizationsByUserEmail : [];
  }, [data]);

  const submitEmail = useCallback(
    (e) => {
      e.preventDefault();
      if (!isValidEmail(userEmail)) {
        setErrorText(t("enter_valid_email"));
        return;
      }
      getOrgByEmail({
        variables: {
          email: userEmail,
        },
      });
      setErrorText(false);
      if (orgData.length) {
        setShowOrgSelect(true);
      }
    },
    [t, getOrgByEmail, userEmail, orgData]
  );

  useEffect(() => {
    if (!orgData.length) {
      return;
    }
    if (orgData.length > 1) {
      setShowOrgSelect(true);
      return;
    }
    getHistory().push(
      // `props.location.search` holds all of the query params.
      `/${orgData[0].slug}/login${props.location.search}`,
      { org: orgData[0] }
    );
  }, [orgData, props.location.search]);

  return showOrgSelect ? (
    <OrgSelector
      userOrgs={orgData}
      props={props}
      setShowOrgSelect={setShowOrgSelect}
    />
  ) : (
    <Fragment>
      <Header showSignUp={true} />

      <InnerWrapper>
        <form name="emailLoginForm" onSubmit={submitEmail}>
          <Heading02 mb="24px" style={{ textAlign: "center" }}>
            {t("email_login_header")}
          </Heading02>

          <FieldContainer>
            <Input
              type="text"
              id="org"
              name="org"
              placeholder={t("email_input_placeholder")}
              autoFocus
              required
              onChange={handleEmailInput}
              error={errorText || error}
              errorText={error ? t("email_unknown") : errorText}
            />
          </FieldContainer>
          <FieldContainer style={{ marginBottom: "0" }}>
            <Button
              primary
              type="submit"
              style={{ width: "100%" }}
              onClick={submitEmail}
            >
              <span>
                {loading
                  ? t("button_state_loading")
                  : t("button_action_continue")}
              </span>
            </Button>
          </FieldContainer>
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="baseline"
            justifyContent="center"
            mt="32px"
          >
            <Body05 marginRight="4px">{t("robin_domain")}</Body05>

            <Link
              style={{ fontSize: "16px" }}
              onClick={() => showEmailLoginScreen(false)}
              accessible="true"
            >
              {t("url_sign_in")}
            </Link>
          </Flex>
        </form>
      </InnerWrapper>
    </Fragment>
  );
};
