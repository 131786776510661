const validScopeMap = {
  bot: [
    'settings:user:read',
    'settings:user:write',
    'integrations:user:read',
    'integrations:user:write',
    'identity',
    'identity.organizations',
    'places:read',
    'offline_access',
    'reservation:seat:manage',
    'directory:users:read'
  ],
  'scheduling:events:write': 'Create and modify events on your behalf',
  'scheduling:events:read': 'Read data from events you belong to',
  'places:read': 'Read data from places you can access',
  'places:write': 'Write data to places you can access',
  'directory:organizations:read': 'Read data from organizations you belong to',
  'directory:users:read': 'Read user data from organizations you belong to',
  'settings:user:read': 'Read your Robin settings and preferences',
  'settings:user:write': 'Modify your Robin settings and preferences',
  'integrations:user:write': 'Manage your Robin integrations',
  'integrations:user:read': 'Read data from your Robin integrations',
  identity: 'View your Robin user data',
  'identity.organizations': 'View the Robin organizations you belong to',
  offline_access: 'Maintain access to data you have given it access to'
};

export default validScopeMap;
