import React, {Component, Fragment} from 'react';
import Header from './Header.jsx';
import {LoudTitle} from './common/type.jsx';
import {InnerWrapper} from './layout/index.jsx';
import spacing from './common/spacing.js';

export default class NotFound extends Component {
  render () {
    return (
      <Fragment>
        <Header showSignUp={false} />

        <InnerWrapper>
          <LoudTitle style={{
            textAlign: 'center',
            marginTop: spacing(10),
            fontSize: 48
          }}>
            404 Not found
          </LoudTitle>
        </InnerWrapper>
      </Fragment>
    );
  }
}
