/**
 * These colors should be kept in sync with the Global Styles doc on Figma.
 * https://www.figma.com/file/f6GxbqCw2nZiWHceK92S1K/00-Global-Styles?node-id=1%3A72
 */

/**
 * Deprecated color scheme, used before rebranding
//  * @deprecated
 * DO NOT USE IN NEW CODE
 */
const LegacyDefinitions = {
  /**
   * Neutrals
   */
  N900: {
    group: 'neutral',
    name: 'Soul of Brian',
    value: '#070B0E',
  },
  N800: {
    group: 'neutral',
    name: 'Zero Dark',
    value: '#0E1924',
  },
  N700: {
    group: 'neutral',
    name: 'Ink',
    value: '#10293C',
  },
  N500: {
    group: 'neutral',
    name: 'Pet Rock',
    value: '#4E6275',
  },
  N400: {
    group: 'neutral',
    name: 'Wet Concrete',
    value: '#718191',
  },
  N300: {
    group: 'neutral',
    name: 'Metal',
    value: '#95A1AC',
  },
  N200: {
    group: 'neutral',
    name: 'Concrete',
    value: '#D2DAE1',
  },
  N100: {
    group: 'neutral',
    name: 'Ghost',
    value: '#ECEFF1',
  },
  N050: {
    group: 'neutral',
    name: 'London Fog',
    value: '#F5F7F8',
  },
  N000: {
    group: 'neutral',
    name: 'White Heat',
    value: '#FFFFFF',
  },

  /**
   * Blues
   */
  B500: {
    group: 'blue',
    name: 'Mariner',
    value: '#1E56AE',
  },
  B400: {
    group: 'blue',
    name: 'Navy Blue',
    value: '#0A64BF',
  },
  B350: {
    group: 'blue',
    name: 'Da Bee Dee',
    value: '#0D74E5',
  },
  B300: {
    group: 'blue',
    name: 'Windex™️',
    value: '#008BF1',
  },
  B200: {
    group: 'blue',
    name: 'Maya Blue',
    value: '#5BBBFF',
  },
  B100: {
    group: 'blue',
    name: 'Soft Sky',
    value: '#C5E9FF',
  },
  B050: {
    group: 'blue',
    name: 'Solitude',
    value: '#EBF7FF',
  },

  /**
   * Greens
   */
  G500: {
    group: 'green',
    name: 'Viridian',
    value: '#308465',
  },
  G400: {
    group: 'green',
    name: 'Eucalyptus',
    value: '#2A9864',
  },
  G300: {
    group: 'green',
    name: 'Sea Green',
    value: '#23AE5D',
  },
  G200: {
    group: 'green',
    name: 'Maya Green',
    value: '#6ED299',
  },
  G100: {
    group: 'green',
    name: 'Modern Mint',
    value: '#CBF2DC',
  },
  G050: {
    group: 'green',
    name: 'Faded Mint',
    value: '#EFFBF4',
  },

  /**
   * Reds
   */
  R500: {
    group: 'red',
    name: 'Totem Pole',
    value: '#8C2D18',
  },
  R400: {
    group: 'red',
    name: 'Thunderbird',
    value: '#BD3A2C',
  },
  R350: {
    group: 'red',
    name: 'Scarlet',
    value: '#CE3F30',
  },
  R300: {
    group: 'red',
    name: 'Robin Red',
    value: '#EE3F46',
  },
  R200: {
    group: 'red',
    name: 'Geraldine',
    value: '#ED8D8F',
  },
  R100: {
    group: 'red',
    name: 'We Peep',
    value: '#F9D7D9',
  },

  /**
   * Yellows
   */
  Y500: {
    group: 'yellow',
    name: 'Ginger',
    value: '#A06021',
  },
  Y400: {
    group: 'yellow',
    name: 'Meteor',
    value: '#C97D2B',
  },
  Y300: {
    group: 'yellow',
    name: 'Gold Chain',
    value: '#F19F35',
  },
  Y200: {
    group: 'yellow',
    name: 'Mute Tangerine',
    value: '#FDC47A',
  },
  Y100: {
    group: 'yellow',
    name: 'Sandy Beach',
    value: '#FFEBCE',
  },
};

const Definitions = {
  Maroon5: {
    group: 'Primary',
    value: '#F4F1F2',
    name: 'Maroon 5',
    subgroup: 'Maroon',
  },
  Maroon10: {
    group: 'Primary',
    value: '#E8E1E5',
    name: 'Maroon 10',
    subgroup: 'Maroon',
  },
  Maroon20: {
    group: 'Primary',
    value: '#D7CAD1',
    name: 'Maroon 20',
    subgroup: 'Maroon',
  },
  Maroon30: {
    group: 'Primary',
    value: '#C5B1BC',
    name: 'Maroon 30',
    subgroup: 'Maroon',
  },
  Maroon40: {
    group: 'Primary',
    value: '#B498A8',
    name: 'Maroon 40',
    subgroup: 'Maroon',
  },
  Maroon50: {
    group: 'Primary',
    value: '#A28194',
    name: 'Maroon 50',
    subgroup: 'Maroon',
  },
  Maroon60: {
    group: 'Primary',
    value: '#906880',
    name: 'Maroon 60',
    subgroup: 'Maroon',
  },
  Maroon70: {
    group: 'Primary',
    value: '#7F506C',
    name: 'Maroon 70',
    subgroup: 'Maroon',
  },
  Maroon80: {
    group: 'Primary',
    value: '#6E3858',
    name: 'Maroon 80',
    subgroup: 'Maroon',
  },
  Maroon90: {
    group: 'Primary',
    value: '#5E2244',
    name: 'Maroon 90',
    subgroup: 'Maroon',
  },
  Maroon100: {
    group: 'Primary',
    value: '#550331',
    name: 'Maroon 100',
    subgroup: 'Maroon',
  },
  Maroon110: {
    group: 'Primary',
    value: '#3C0222',
    name: 'Maroon 110',
    subgroup: 'Maroon',
  },
  Magenta5: {
    group: 'Primary',
    value: '#F6EFF3',
    name: 'Magenta 5',
    subgroup: 'Magenta',
  },
  Magenta10: {
    group: 'Primary',
    value: '#F0E4EB',
    name: 'Magenta 10',
    subgroup: 'Magenta',
  },
  Magenta20: {
    group: 'Primary',
    value: '#E5CFDC',
    name: 'Magenta 20',
    subgroup: 'Magenta',
  },
  Magenta30: {
    group: 'Primary',
    value: '#DBBACD',
    name: 'Magenta 30',
    subgroup: 'Magenta',
  },
  Magenta40: {
    group: 'Primary',
    value: '#D1A4BF',
    name: 'Magenta 40',
    subgroup: 'Magenta',
  },
  Magenta50: {
    group: 'Primary',
    value: '#C78FB0',
    name: 'Magenta 50',
    subgroup: 'Magenta',
  },
  Magenta60: {
    group: 'Primary',
    value: '#BE7AA2',
    name: 'Magenta 60',
    subgroup: 'Magenta',
  },
  Magenta70: {
    group: 'Primary',
    value: '#B56593',
    name: 'Magenta 70',
    subgroup: 'Magenta',
  },
  Magenta80: {
    group: 'Primary',
    value: '#AC5285',
    name: 'Magenta 80',
    subgroup: 'Magenta',
  },
  Magenta90: {
    group: 'Primary',
    value: '#A23E77',
    name: 'Magenta 90',
    subgroup: 'Magenta',
  },
  Magenta100: {
    group: 'Primary',
    value: '#A81F6B',
    name: 'Magenta 100',
    subgroup: 'Magenta',
  },
  Magenta110: {
    group: 'Primary',
    value: '#7D1750',
    name: 'Magenta 110',
    subgroup: 'Magenta',
  },
  White0: {
    group: 'Primary',
    value: '#FFFFFF',
    name: 'White 0',
    subgroup: 'White',
  },

  Gray5: {
    group: 'Secondary',
    value: '#F7F6F6',
    name: 'Gray 5',
    subgroup: 'Gray',
  },

  Gray10: {
    group: 'Secondary',
    value: '#EEEDED',
    name: 'Gray 10',
    subgroup: 'Gray',
  },
  Gray20: {
    group: 'Secondary',
    value: '#DCDADA',
    name: 'Gray 20',
    subgroup: 'Gray',
  },
  Gray30: {
    group: 'Secondary',
    value: '#CBC9C9',
    name: 'Gray 30',
    subgroup: 'Gray',
  },
  Gray40: {
    group: 'Secondary',
    value: '#ABA8A8',
    name: 'Gray 40',
    subgroup: 'Gray',
  },
  Gray50: {
    group: 'Secondary',
    value: '#8C8989',
    name: 'Gray 50',
    subgroup: 'Gray',
  },
  Gray60: {
    group: 'Secondary',
    value: '#706E6E',
    name: 'Gray 60',
    subgroup: 'Gray',
  },
  Gray70: {
    group: 'Secondary',
    value: '#626060',
    name: 'Gray 70',
    subgroup: 'Gray',
  },
  Gray80: {
    group: 'Secondary',
    value: '#484747',
    name: 'Gray 80',
    subgroup: 'Gray',
  },
  Gray90: {
    group: 'Secondary',
    value: '#3E3D3D',
    name: 'Gray 90',
    subgroup: 'Gray',
  },
  Gray100: {
    group: 'Secondary',
    value: '#2A2A2A',
    name: 'Gray 100',
    subgroup: 'Gray',
  },
  Gray110: {
    group: 'Secondary',
    value: '#1C1C1C',
    name: 'Gray 110',
    subgroup: 'Gray',
  },
  Tan5: {
    group: 'Secondary',
    value: '#FBFAF9',
    name: 'Tan 5',
    subgroup: 'Tan',
  },
  Tan10: {
    group: 'Secondary',
    value: '#F8F6F4',
    name: 'Tan 10',
    subgroup: 'Tan',
  },
  Tan20: {
    group: 'Secondary',
    value: '#F5F2F0',
    name: 'Tan 20',
    subgroup: 'Tan',
  },
  Tan30: {
    group: 'Secondary',
    value: '#F2EFEB',
    name: 'Tan 30',
    subgroup: 'Tan',
  },
  Tan40: {
    group: 'Secondary',
    value: '#EEEBE7',
    name: 'Tan 40',
    subgroup: 'Tan',
  },
  Tan50: {
    group: 'Secondary',
    value: '#EBE7E2',
    name: 'Tan 50',
    subgroup: 'Tan',
  },
  Tan60: {
    group: 'Secondary',
    value: '#E7E3DE',
    name: 'Tan 60',
    subgroup: 'Tan',
  },
  Tan70: {
    group: 'Secondary',
    value: '#E4DFDA',
    name: 'Tan 70',
    subgroup: 'Tan',
  },
  Tan80: {
    group: 'Secondary',
    value: '#E1DCD5',
    name: 'Tan 80',
    subgroup: 'Tan',
  },
  Tan90: {
    group: 'Secondary',
    value: '#DED8D1',
    name: 'Tan 90',
    subgroup: 'Tan',
  },
  Tan100: {
    group: 'Secondary',
    value: '#DCD4CB',
    name: 'Tan 100',
    subgroup: 'Tan',
  },
  Tan110: {
    group: 'Secondary',
    value: '#CCC0B3',
    name: 'Tan 110',
    subgroup: 'Tan',
  },
  Red5: {
    group: 'Secondary',
    value: '#FBF4F4',
    name: 'Red 5',
    subgroup: 'Red',
  },
  Red10: {
    group: 'Secondary',
    value: '#F7E9E7',
    name: 'Red 10',
    subgroup: 'Red',
  },
  Red20: {
    group: 'Secondary',
    value: '#F3D8D7',
    name: 'Red 20',
    subgroup: 'Red',
  },
  Red30: {
    group: 'Secondary',
    value: '#EFC7C6',
    name: 'Red 30',
    subgroup: 'Red',
  },
  Red40: {
    group: 'Secondary',
    value: '#EBB7B5',
    name: 'Red 40',
    subgroup: 'Red',
  },
  Red50: {
    group: 'Secondary',
    value: '#E8A6A3',
    name: 'Red 50',
    subgroup: 'Red',
  },
  Red60: {
    group: 'Secondary',
    value: '#E59593',
    name: 'Red 60',
    subgroup: 'Red',
  },
  Red70: {
    group: 'Secondary',
    value: '#E28581',
    name: 'Red 70',
    subgroup: 'Red',
  },
  Red80: {
    group: 'Secondary',
    value: '#E07571',
    name: 'Red 80',
    subgroup: 'Red',
  },
  Red90: {
    group: 'Secondary',
    value: '#DD6661',
    name: 'Red 90',
    subgroup: 'Red',
  },
  RedProduct: {
    group: 'Secondary',
    value: '#E81C1C',
    name: 'Red (Product)',
  },
  Red110: {
    group: 'Secondary',
    value: '#BF1717',
    name: 'Red 110',
    subgroup: 'Red',
  },
  Yellow5: {
    group: 'Secondary',
    value: '#FCF9F2',
    name: 'Yellow 5',
    subgroup: 'Yellow',
  },
  Yellow10: {
    group: 'Secondary',
    value: '#FAF4E6',
    name: 'Yellow 10',
    subgroup: 'Yellow',
  },
  Yellow20: {
    group: 'Secondary',
    value: '#F9EDD4',
    name: 'Yellow 20',
    subgroup: 'Yellow',
  },
  Yellow30: {
    group: 'Secondary',
    value: '#F8E8C2',
    name: 'Yellow 30',
    subgroup: 'Yellow',
  },
  Yellow40: {
    group: 'Secondary',
    value: '#F7E1B0',
    name: 'Yellow 40',
    subgroup: 'Yellow',
  },
  Yellow50: {
    group: 'Secondary',
    value: '#F6DB9E',
    name: 'Yellow 50',
    subgroup: 'Yellow',
  },
  Yellow60: {
    group: 'Secondary',
    value: '#F6D68C',
    name: 'Yellow 60',
    subgroup: 'Yellow',
  },
  Yellow70: {
    group: 'Secondary',
    value: '#F5CF7C',
    name: 'Yellow 70',
    subgroup: 'Yellow',
  },
  Yellow80: {
    group: 'Secondary',
    value: '#F5C96C',
    name: 'Yellow 80',
    subgroup: 'Yellow',
  },
  Yellow90: {
    group: 'Secondary',
    value: '#F4C25D',
    name: 'Yellow 90',
    subgroup: 'Yellow',
  },
  Yellow100: {
    group: 'Secondary',
    value: '#FDBA31',
    name: 'Yellow 100',
    subgroup: 'Yellow',
  },
  Yellow110: {
    group: 'Secondary',
    value: '#F0A330',
    name: 'Yellow 110',
    subgroup: 'Yellow',
  },
  Blue5: {
    group: 'Secondary',
    value: '#F4F7FB',
    name: 'Blue 5',
    subgroup: 'Blue',
  },
  Blue10: {
    group: 'Secondary',
    value: '#E6ECF5',
    name: 'Blue 10',
    subgroup: 'Blue',
  },
  Blue20: {
    group: 'Secondary',
    value: '#D1DFEF',
    name: 'Blue 20',
    subgroup: 'Blue',
  },
  Blue30: {
    group: 'Secondary',
    value: '#BBD1EA',
    name: 'Blue 30',
    subgroup: 'Blue',
  },
  Blue40: {
    group: 'Secondary',
    value: '#A5C3E6',
    name: 'Blue 40',
    subgroup: 'Blue',
  },
  Blue50: {
    group: 'Secondary',
    value: '#90B6E1',
    name: 'Blue 50',
    subgroup: 'Blue',
  },
  Blue60: {
    group: 'Secondary',
    value: '#7BA8DD',
    name: 'Blue 60',
    subgroup: 'Blue',
  },
  Blue70: {
    group: 'Secondary',
    value: '#659AD7',
    name: 'Blue 70',
    subgroup: 'Blue',
  },
  Blue80: {
    group: 'Secondary',
    value: '#518CD3',
    name: 'Blue 80',
    subgroup: 'Blue',
  },
  Blue90: {
    group: 'Secondary',
    value: '#3D80CF',
    name: 'Blue 90',
    subgroup: 'Blue',
  },
  Blue100: {
    group: 'Secondary',
    value: '#2774C1',
    name: 'Blue 100',
    subgroup: 'Blue',
  },
  Blue110: {
    group: 'Secondary',
    value: '#2163A5',
    name: 'Blue 110',
    subgroup: 'Blue',
  },

  GreenProduct: {
    group: 'Secondary',
    value: '#6D9F3D',
    name: 'Green (Product)',
  },
  Green100: {
    group: 'Secondary',
    value: '#85BC50',
    name: 'Green 100',
  },

  Green10: {
    group: 'Secondary',
    value: '#F0F4E9',
    name: 'Green 10',
    subgroup: 'Green',
  },
  Green20: {
    group: 'Secondary',
    value: '#E5EED9',
    name: 'Green 20',
    subgroup: 'Green',
  },
  Green30: {
    group: 'Secondary',
    value: '#DBE6C9',
    name: 'Green 30',
    subgroup: 'Green',
  },
  Green40: {
    group: 'Secondary',
    value: '#D0E0B9',
    name: 'Green 40',
    subgroup: 'Green',
  },
  Green50: {
    group: 'Secondary',
    value: '#C5DAA9',
    name: 'Green 50',
    subgroup: 'Green',
  },
  Green60: {
    group: 'Secondary',
    value: '#BBD49B',
    name: 'Green 60',
    subgroup: 'Green',
  },
  Green70: {
    group: 'Secondary',
    value: '#B0CE8B',
    name: 'Green 70',
    subgroup: 'Green',
  },
  Green80: {
    group: 'Secondary',
    value: '#A6C77C',
    name: 'Green 80',
    subgroup: 'Green',
  },
  Green90: {
    group: 'Secondary',
    value: '#9BC16D',
    name: 'Green 90',
    subgroup: 'Green',
  },
} as const;

// mapping old color scheme to rebranding
// NOTE: Most Magenta100 mappings were remapped to Maroon100 after the fact!!!
// Use Magenta100 sparingly, when approved by design team
LegacyDefinitions.R400 = Definitions.Maroon100;
LegacyDefinitions.B400 = Definitions.Magenta100;
LegacyDefinitions.N900 = Definitions.Gray100;
LegacyDefinitions.N800 = Definitions.Gray90;
LegacyDefinitions.N700 = Definitions.Gray70;
LegacyDefinitions.N500 = Definitions.Gray40;
LegacyDefinitions.N400 = Definitions.Gray10;
LegacyDefinitions.N300 = Definitions.Gray10;
LegacyDefinitions.N200 = Definitions.Tan70;
LegacyDefinitions.N100 = Definitions.Tan30;
LegacyDefinitions.N050 = Definitions.Tan5;
LegacyDefinitions.N000 = Definitions.White0;
LegacyDefinitions.B500 = Definitions.Magenta100;
LegacyDefinitions.B400 = Definitions.Magenta100;
LegacyDefinitions.B350 = Definitions.Maroon100;
LegacyDefinitions.B300 = Definitions.Maroon100;
LegacyDefinitions.B200 = Definitions.Maroon90;
LegacyDefinitions.B100 = Definitions.Maroon20;
LegacyDefinitions.B050 = Definitions.Maroon20;
LegacyDefinitions.G500 = Definitions.GreenProduct;
LegacyDefinitions.G400 = Definitions.GreenProduct;
LegacyDefinitions.G300 = Definitions.Green90;
LegacyDefinitions.G200 = Definitions.Green90;
LegacyDefinitions.G100 = Definitions.Green20;
LegacyDefinitions.G050 = Definitions.Green20;
LegacyDefinitions.R500 = Definitions.Red110;
LegacyDefinitions.R400 = Definitions.Red110;
LegacyDefinitions.R350 = Definitions.RedProduct;
LegacyDefinitions.R300 = Definitions.RedProduct;
LegacyDefinitions.R200 = Definitions.Red20;
LegacyDefinitions.R100 = Definitions.Red20;
LegacyDefinitions.Y500 = Definitions.Yellow100;
LegacyDefinitions.Y100 = Definitions.Yellow20;

/**
 * Deprecated palette syntax for color definitions
 * @deprecated
 * DO NOT USE IN NEW CODE
 */
const LegacyPalette = {
  ZeroDark: '#0E1924',
  Ink: '#10293C',
  PetRock: '#4E6275',
  WetConcrete: '#718191',
  Metal: '#95A1AC',
  Concrete: '#D2DAE1',
  Ghost: '#ECEFF1',
  White: '#FFFFFF',
  GoldChain: '#F3A93C',
  Astroturf: '#27B768',
  DarkWindex: '#0078B8',
  Windex: '#00A0F5',
  KingPurple: '#575189',
  GlamBerry: '#B0588D',
  Lily: '#DD758D',
  SimpleSalmon: '#EF6D67',
  Rorange: '#FD4B46',
  RobinRed: '#EE3F46',
};

// rebranding to new branding
LegacyPalette.ZeroDark = Definitions.Gray100.value;
LegacyPalette.Ink = Definitions.Gray70.value;
LegacyPalette.PetRock = Definitions.Gray60.value;
LegacyPalette.WetConcrete = Definitions.Gray40.value;
LegacyPalette.Metal = Definitions.Gray20.value;
LegacyPalette.Concrete = Definitions.Tan20.value;
LegacyPalette.Ghost = Definitions.Tan10.value;
LegacyPalette.White = Definitions.White0.value;
LegacyPalette.GoldChain = Definitions.Yellow100.value;
LegacyPalette.Astroturf = Definitions.GreenProduct.value;
LegacyPalette.DarkWindex = Definitions.Maroon100.value;
LegacyPalette.Windex = Definitions.Maroon100.value;
LegacyPalette.KingPurple = Definitions.Magenta90.value;
LegacyPalette.GlamBerry = Definitions.Magenta90.value;
LegacyPalette.Lily = Definitions.RedProduct.value;
LegacyPalette.SimpleSalmon = Definitions.RedProduct.value;
LegacyPalette.Rorange = Definitions.RedProduct.value;
LegacyPalette.RobinRed = Definitions.RedProduct.value;

export type ColorDefinition = {
  group: string;
  name: string;
  key: keyof typeof Definitions;
  value: string;
};

export const ColorDefinitionsByKey = Object.keys(Definitions).reduce(
  (acc: {[key: string]: ColorDefinition}, key) => {
    const colorKey = key as ColorDefinition['key'];
    acc[key] = {
      key: colorKey,
      ...Definitions[colorKey],
    };
    return acc;
  },
  {}
) as Record<ColorDefinition['key'], ColorDefinition>;
