import React from 'react';
import {useToasts} from 'react-toast-notifications';
import {ToastContainer, DefaultToast} from './toast';

const ErrorToast = ({error}) => {
  const {addToast, removeToast} = useToasts();

  React.useEffect(() => {
    if (!error) {
      return;
    }

    const toastId = addToast(error.message ? error.message : 'Unknown error occurred', {
      appearance: 'error',
      autoDismiss: true,
      autoDismissTimeout: 2.5 * 1000,
      components: {Toast: DefaultToast, ToastContainer}
    });

    return () => removeToast(toastId);
  }, [error, addToast, removeToast]);

  return null;
};

export default ErrorToast;
