import styled from '@emotion/styled';
import {keyframes} from '@emotion/react';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';
import {Colors} from '../Color';

type SkeletonProps = LayoutProps & SpaceProps & FlexboxProps;

const transparent = `rgba(255, 255, 255, 0)`;
const pulseColor = Colors.Tan70;

const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Skeleton = styled('div')<SkeletonProps>`
  background-color: ${Colors.Tan5};
  border-radius: 8px;
  box-shadow: none;
  overflow: hidden;
  position: relative;
  &:after {
    animation: ${animation} 1.6s infinite;
    background: linear-gradient(
      -90deg,
      ${transparent},
      ${pulseColor},
      ${transparent}
    );
    background-size: 100%;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  ${layout};
  ${space};
  ${flexbox};
`;
