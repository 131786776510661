import React, { Component } from "react";
import validScopeMap from "../../constants/scopes";
import { LoudTitle, Text } from "./common/type.jsx";
import spacing from "./common/spacing";
import Button from "./Button.jsx";
import { InnerWrapper } from "./layout/index.jsx";
import { FieldContainer } from "./TextInput.jsx";

const listStyle = {
  "padding-bottom": "15px",
  "padding-left": "20px",
  "list-style-type": "disc",
};

export default class ScopeConfirm extends Component {
  constructor(props) {
    super(props);

    const splitScopes = props.scopes.split(/\s|,/);

    this.state = {
      scopes: splitScopes,
      scopeMap: splitScopes.reduce((ob, scope) => {
        let [category, permission] = scope.split(":");
        if (!ob[category]) {
          ob[category] = [];
        }
        ob[category].push(permission);
        return ob;
      }, {}),
    };
  }

  render() {
    const expandedScopes = this.state.scopes.reduce((carry, key) => {
      if (Array.isArray(validScopeMap[key])) {
        carry.push(...validScopeMap[key]);
      } else {
        carry.push(key);
      }

      return carry;
    }, []);

    return (
      <InnerWrapper>
        <FieldContainer>
          <LoudTitle style={{ "padding-bottom": "15px" }}>
            This will allow us to:
          </LoudTitle>

          <ul style={{ listStyle }}>
            {expandedScopes.map((key) => {
              if (validScopeMap[key]) {
                return (
                  <li style={{ marginBottom: spacing(0.5) }} key={key}>
                    <Text>{validScopeMap[key]}</Text>
                  </li>
                );
              }
            })}
          </ul>
          <Button
            type="submit"
            style={{ width: "100%" }}
            primary
            onClick={() => this.props.onConfirm(this.state.scopes.join(","))}
            className="btn big"
            value="Confirm"
          />
        </FieldContainer>
      </InnerWrapper>
    );
  }
}
