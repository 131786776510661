import ky from 'ky-universal';
import Url from 'url-parse';

import {google, office365, office365Graph} from '../config/sso';
if (typeof window === 'undefined') {
  global.window = {};
}

const {JSO, Popup} = require('jso');

export function getGoogleConfig (baseUrl) {
  return {
    providerID: 'google',
    client_id: google.client_id,
    redirect_uri: `${baseUrl}/api/oauth/google`,
    response_type: 'code',
    authorization: google.authorization,
    token_endpoint: `${baseUrl}/api/oauth/google`,
    client_secret: 'test',
    scopes: google.scopes
  };
}

export function getOffice365Config (baseUrl) {
  return {
    providerID: 'office365',
    client_id: office365.client_id,
    resource: office365.resource,
    authorization: office365.authorization,
    response_type: 'code',
    redirect_uri: `${baseUrl}/api/oauth/office365`,
    token_endpoint: `${baseUrl}/api/oauth/office365`,
    client_secret: 'test'
  };
}

export function getOffice365GraphConfig (baseUrl) {
  return {
    providerID: 'graph',
    client_id: office365Graph.client_id,
    resource: office365.resource,
    authorization: office365Graph.authorization,
    response_type: 'code',
    redirect_uri: `${baseUrl}/api/oauth/graph`,
    token_endpoint: `${baseUrl}/api/oauth/graph`,
    client_secret: 'test',
    scopes: office365Graph.scopes
  };
}

export function getSAMLConfig (baseUrl, orgID, responseType = 'code') {
  const baseRelayState = encodeURIComponent(`${baseUrl}/api/oauth/saml`);

  return {
    providerID: 'saml',
    client_id: 'saml',
    response_type: responseType,
    authorization: `${baseUrl}/api/oauth/saml-redirect?orgID=${orgID}&baseRelayState=${baseRelayState}`,
    token_endpoint: `${baseUrl}/api/oauth/saml`,
    prompt: 'consent'
  };
}

export function getSAMLMethods () {
  return [
    'saml',
    'onelogin',
    'okta',
    'rippling'
  ];
}

export function getClient (config, usePopup = false) {
  const j = new JSO(config);

  if (usePopup) {
    j.setLoader(Popup);
  }

  j.on('authorizationCode', ({state, code}) => {
    let json = {
          grant_type: 'authorization_code',
          code,
          redirect_uri: config.redirect_uri
        },
        // For some reason, getState is a destructive action that removes the state from localStorage.
        // A successfully completed callback doesn't prevent the original JSO#processAuthorizationCodeResponse handler
        // from running, which will also gets and removes the state.
        // So, we'll get the state here, catch when the original handler fails, and call j.codeExchange.
        storedState = j.store.getState(state);

    // Also, no handle is returned for this callback, so we need to assign it to the object and grab it later.
    j.codeExchange = ky.post(
      config.token_endpoint,
      {
        json,
        credentials: 'include'
      }
    ).json()
      .then((response) => {
        j.processReceivedToken(response.data, storedState);

        return response;
      });
  });

  return j;
}

export function handleCodeExchange ({scope, state, response_type, client_id, redirect_uri}) {
  ky(`/authorize`, {
    method: 'POST',
    json: {
      scope,
      state,
      response_type,
      client_id,
      redirect_uri
    }
  }).json()
    .then((response) => {
      // Parse out the code from a given URL.
      const url = new Url(response.url, true);

      return {code: url.query.code};
    });
}
