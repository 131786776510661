import styled from '@emotion/styled';
import {Colors} from '../../Color';
import {
  compose,
  color,
  ColorProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';
import {CoreFontSize, CoreFontWeight} from '../constants';
import {Theme} from '@emotion/react';
import {getMainFont} from './../../helpers/getMainFont';

type BodyProps = ColorProps & SpaceProps & LayoutProps & FlexboxProps;
type CoreStyleProps = {
  color: string;
  display: string;
  fontFamily: string;
  fontWeight: number;
  fontFeatureSettings: string;
};
type BodyStyleProps = {
  fontSize: string;
  lineHeight: string;
};

const BodyStyledSystem = compose(color, space, layout, flexbox);

const BaseBodyStyles = (theme: Theme): CoreStyleProps => ({
  color: Colors.Gray80,
  display: 'flex',
  fontFamily: getMainFont(theme),
  fontWeight: CoreFontWeight.Regular,
  fontFeatureSettings: "'ss02' on",
});

export const Body01Styles: BodyStyleProps = {
  fontSize: CoreFontSize.s26,
  lineHeight: '24px',
};
/**
 * Component used as primary body copy.
 *
 * color: N500; fontSize: 26px; fontWeight: 400
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Body01 = styled.span<BodyProps>(
  ({theme}) => BaseBodyStyles(theme),
  Body01Styles,
  BodyStyledSystem
);

export const Body02Styles: BodyStyleProps = {
  fontSize: CoreFontSize.s22,
  lineHeight: '26px',
};
/**
 * Component used as intro copy.
 *
 * color: N500; fontSize: 22px; fontWeight: 400
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Body02 = styled.span<BodyProps>(
  ({theme}) => BaseBodyStyles(theme),
  Body02Styles,
  BodyStyledSystem
);

export const Body03Styles: BodyStyleProps = {
  fontSize: CoreFontSize.s20,
  lineHeight: '22px',
};
/**
 * Component used as large body copy.
 *
 * color: N500; fontSize: 20px; fontWeight: 400
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Body03 = styled.span<BodyProps>(
  ({theme}) => BaseBodyStyles(theme),
  Body03Styles,
  BodyStyledSystem
);

export const Body04Styles: BodyStyleProps = {
  fontSize: CoreFontSize.s18,
  lineHeight: '22px',
};
/**
 * Component used as small body copy when content needs a supplementary message.
 *
 * color: N500; fontSize: 18px; fontWeight: 400
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Body04 = styled.span<BodyProps>(
  ({theme}) => BaseBodyStyles(theme),
  Body04Styles,
  BodyStyledSystem
);

export const Body05Styles: BodyStyleProps = {
  fontSize: CoreFontSize.s16,
  lineHeight: '22px',
};
/**
 * Component used as small body copy when content needs a supplementary message.
 *
 * color: N500; fontSize: 16px; fontWeight: 400
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Body05 = styled.span<BodyProps>(
  ({theme}) => BaseBodyStyles(theme),
  Body05Styles,
  BodyStyledSystem
);

export const Body06Styles: BodyStyleProps = {
  fontSize: CoreFontSize.s14,
  lineHeight: '18px',
};
/**
 * Component used as small body copy when content needs a supplementary message.
 *
 * color: N500; fontSize: 14px; fontWeight: 400
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Body06 = styled.span<BodyProps>(
  ({theme}) => BaseBodyStyles(theme),
  Body06Styles,
  BodyStyledSystem
);

export const Body07Styles: BodyStyleProps = {
  fontSize: CoreFontSize.s12,
  lineHeight: '16px',
};
/**
 * Component used as small body copy when content needs a supplementary message.
 *
 * color: N500; fontSize: 12px; fontWeight: 400
 *
 * For design details, check out Figma:
 * https://www.figma.com/file/MjZEP2KTT3UAYUNevXbZr1/Web:-Components?node-id=3%3A156
 */
export const Body07 = styled.span<BodyProps>(
  ({theme}) => BaseBodyStyles(theme),
  Body07Styles,
  BodyStyledSystem
);
