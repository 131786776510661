import {createBrowserHistory, createMemoryHistory} from 'history';

let history = null;

/**
 * Gets the router history.
 *
 * @return {Object} Router history.
 */
export function getHistory () {
  if (!history) {
    if (typeof document === 'undefined') {
      history = createMemoryHistory();
    } else {
      history = createBrowserHistory({
        basename: '/'
      });
    }
  }
  return history;
}

export default getHistory;
