/* eslint-disable react/jsx-no-literals */
import React, { Component } from "react";
import styled from "@emotion/styled";
import Button from "./Button.jsx";
import spacing from "./common/spacing";
import { Colors } from "@robinpowered/design-system";

const StyledHeader = styled.header`
  transition: none;
  text-align: left;
  width: 100%;
  zoom: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  height: 54px;
  display: flex;
  background-color: ${Colors.White0};

  a {
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;
  }
`;

const Inner = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 50%;
`;

const LogoWrapper = styled.div`
  a:hover {
    opacity: 0.66;
  }

  img {
    max-width: ${spacing(10)};
  }
`;

/**
 * Header for the login states.
 */
export default class Header extends Component {
  render() {
    return (
      <StyledHeader>
        <Inner>
          <LogoWrapper>
            <a
              href="https://robinpowered.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="https://static.robinpowered.com/brand/public/logos/full-color.png"
                className="logo"
                alt="Robin"
              />
            </a>
          </LogoWrapper>
          {this.props.showSignUp && (
            <a
              id="header-sign-in"
              href="https://robinpowered.com/free-trial"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button>Sign up</Button>
            </a>
          )}
        </Inner>
      </StyledHeader>
    );
  }
}
