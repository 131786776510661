import {ColorDefinitionsByKey, ColorDefinition} from './ColorDefinitions';

type ColorsHexMap = Record<ColorDefinition['key'], ColorDefinition['value']>;

export const Colors: Readonly<ColorsHexMap> = Object.keys(
  ColorDefinitionsByKey
).reduce((acc, key) => {
  const typedKey = key as ColorDefinition['key'];
  return {...acc, [typedKey]: ColorDefinitionsByKey[typedKey].value};
}, {} as ColorsHexMap);
