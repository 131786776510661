import {css} from '@emotion/react';

export const fontDefinition = (() => {
  if (
    !(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    )
  ) {
    // Don't import fonts when we're in server-side rendering mode.
    return css``;
  }

  return css`
    // 400
    @font-face {
      font-family: 'Brown';
      font-weight: 400;
      src: url(${require('./../fonts/BrownLLWeb-Regular.woff2')})
          format('woff2'),
        url(${require('./../fonts/BrownLLWeb-Regular.woff')}) format('woff');
    }

    @font-face {
      font-family: 'Brown';
      font-weight: 400;
      font-style: italic;
      src: url(${require('./../fonts/BrownLLWeb-Italic.woff2')}) format('woff2'),
        url(${require('./../fonts/BrownLLWeb-Italic.woff')}) format('woff');
    }

    // 500
    @font-face {
      font-family: 'Brown';
      font-weight: 500;
      src: url(${require('./../fonts/BrownLLWeb-Medium.woff2')}) format('woff2'),
        url(${require('./../fonts/BrownLLWeb-Medium.woff')}) format('woff');
    }

    @font-face {
      font-family: 'Brown';
      font-weight: 500;
      font-style: italic;
      src: url(${require('./../fonts/BrownLLWeb-MediumItalic.woff2')})
          format('woff2'),
        url(${require('./../fonts/BrownLLWeb-MediumItalic.woff')})
          format('woff');
    }

    // 700
    @font-face {
      font-family: 'Brown';
      font-weight: 700;
      src: url(${require('./../fonts/BrownLLWeb-Bold.woff2')}) format('woff2'),
        url(${require('./../fonts/BrownLLWeb-Bold.woff')}) format('woff');
    }

    @font-face {
      font-family: 'Brown';
      font-weight: 700;
      font-style: italic;
      src: url(${require('./../fonts/BrownLLWeb-BoldItalic.woff2')})
          format('woff2'),
        url(${require('./../fonts/BrownLLWeb-BoldItalic.woff')}) format('woff');
    }
  `;
})();
