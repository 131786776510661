import React from "react";
import styled from "@emotion/styled";
import { Role } from "./common/colors";
import { Text, Size, Weight, MainFontStack } from "./common/type.jsx";
import spacing from "./common/spacing";
import { Colors } from "@robinpowered/design-system";

const ButtonText = styled(Text)(
  {
    textAlign: "center",
    margin: `0 auto`,
    display: "inline-flex",
    verticalAlign: "center",
    alignSelf: "center",
    justifyContent: "center",
    fontWeight: Weight.Medium,
  },
  (props) =>
    props.loading && {
      visibility: "hidden",
    },
  (props) =>
    props.primary && {
      color: Colors.White0,
    }
);

const ButtonContainer = styled.button(
  {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    height: 40,
    fontFamily: MainFontStack,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "inline-flex",
    lineHeight: 1,
    position: "relative",
    transition: "background-color .2s ease-in-out",
    borderRadius: "8px",
    borderColor: Role.DefaultBorderColor,
    fontSize: "16px",
    fontWeight: "500",
    width: "100%",
    padding: "12px 24px",
    a: {
      textDecoration: "none",
    },
  },
  (props) =>
    !props.linkLike && {
      backgroundColor: Colors.White0,
      border: `1px solid ${Colors.Tan30}`,
      boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)",
      "&:hover, &:focus": {
        backgroundColor: Role.ButtonPrimaryHover,
        outline: "none",
      },
      "&:active": {
        backgroundColor: Role.ButtonPrimaryActive,
        outline: "none",
      },
    },
  (props) =>
    props.icon && {
      borderRadius: "3em",
    },
  (props) =>
    props.primary && {
      backgroundColor: Role.ButtonPrimaryBackground,
      border: "1px solid transparent",
      boxShadow: "1px 2px 1px 0px rgba(50,180,240,0.25)",
      fontWeight: Weight.Medium,
      "&:hover, &:focus": {
        backgroundColor: Role.ButtonPrimaryHover,
      },
      "&:focus": {
        outline: `1px dashed ${Role.ButtonPrimaryBackground}`,
        outlineOffset: 2,
      },
      "&:active": {
        backgroundColor: Role.ButtonPrimaryActive,
      },
    },
  (props) =>
    props.disabled && {
      backgroundColor: Role.ButtonDisabledBackground,
      border: "none",
      boxShadow: "none",
      cursor: "default",
      [ButtonText]: {
        color: Role.ButtonDisabledFont,
      },
      "&:hover, &:focus, &:active": {
        backgroundColor: Role.ButtonDisabledBackground,
      },
    },
  (props) =>
    props.small && {
      padding: `0px ${spacing(1)}`,
      height: 32,
      lineHeight: 1,
      [ButtonText]: {
        fontSize: Size.Small,
      },
    },
  (props) =>
    props.inline && {
      marginLeft: "0.5em",
    }
);

function Button({ children, primary, value, ...props }) {
  return (
    <ButtonContainer primary={primary} {...props}>
      <ButtonText primary={primary}>{children || value}</ButtonText>
    </ButtonContainer>
  );
}

Button.Text = ButtonText;

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
  <Button {...props} innerRef={ref} />
));
