import React, {Component, Fragment} from 'react';
import Header from '../../components/Header.jsx';
import {LoudTitle, DangerText} from '../../components/common/type.jsx';
import {InnerWrapper} from '../../components/layout/index.jsx';
import spacing from '../../components/common/spacing';

/**
 * Selecting the organization to log into.
 */
export default class BadRequestErrorScene extends Component {
  getOrganization (event) {
    event.persist();
    this.delayedCallback(event.target.value);
  }

  render () {
    return (
      <Fragment>
        <Header showSignUp={true} />
        <InnerWrapper className="onboard-form simple-wrap">
          <LoudTitle>We received a bad request:</LoudTitle>
          <div style={{marginTop: spacing(2)}}>
            <DangerText>{this.props.error}</DangerText>
          </div>
        </InnerWrapper>
      </Fragment>
    );
  }
}
