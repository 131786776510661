/* eslint-disable-next-line */
const emailRegExp = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/);
/**
 * Validates that a given sting is formatted like an Email address
 *
 * @param {String} string The potential email address
 * @return {Bool}         Bool value indicating if the string is an email
 */
export function isValidEmail(string) {
  return emailRegExp.test(string);
}
