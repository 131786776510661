import React, {ButtonHTMLAttributes, PropsWithChildren} from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {space, SpaceProps} from 'styled-system';
import {Colors} from '../Color';
import {SpinnerLoader} from '../SpinnerLoader';
import {CoreFontSize, CoreFontWeight} from '../Typography/constants';
import {getMainFont} from '../helpers/getMainFont';

type ButtonProps = PropsWithChildren<
  SpaceProps &
    ButtonHTMLAttributes<HTMLButtonElement> & {
      /** The style of the button to be rendered */
      variant: 'primary' | 'secondary' | 'tertiary';
      size?: 'small' | 'default' | 'large';
      isLoading?: boolean;
      danger?: boolean;
    }
>;

const buttonColors = {
  primary: css`
    border: none;
    color: ${Colors.White0};
    background-color: ${Colors.Maroon100};
    &:hover {
      background-color: ${Colors.Maroon110};
    }
    &:focus {
      background-color: ${Colors.Maroon110};
    }
    &:active {
      background-color: ${Colors.Maroon110};
    }
  `,
  secondary: css`
    background-color: ${Colors.White0};
    border: 1px solid ${Colors.Tan70};
    color: ${Colors.Gray80};
    &:hover {
      border-color: ${Colors.Gray60};
      background-color: ${Colors.Tan5};
      color: ${Colors.Maroon110};
    }
    &:focus {
      background-color: ${Colors.Maroon5};
      color: ${Colors.Maroon110};
    }
    &:active {
      background-color: ${Colors.Maroon10};
    }
  `,
  tertiary: css`
    border: none;
    background-color: transparent;
    color: ${Colors.Gray100};
    &:hover {
      background-color: ${Colors.Tan5};
    }
    &:focus {
      background-color: ${Colors.Maroon5};
    }
    &:active {
      background-color: ${Colors.Maroon5};
    }
  `,
};

const dangerButtonColors = {
  primary: css`
    border: none;
    color: ${Colors.White0};
    background-color: ${Colors.RedProduct};
    &:hover {
      background-color: ${Colors.Red110};
    }
    &:focus {
      background-color: ${Colors.Red110};
    }
    &:active {
      background-color: ${Colors.Red110};
    }
  `,
  secondary: css`
    background-color: ${Colors.White0};
    border: 1px solid ${Colors.RedProduct};
    color: ${Colors.RedProduct};
    &:hover {
      background-color: ${Colors.Red5};
      border: 1px solid ${Colors.Red110};
      color: ${Colors.Red110};
    }
    &:focus {
      background-color: ${Colors.Red5};
    }
    &:active {
      background-color: ${Colors.Red5};
    }
  `,
  tertiary: css`
    border: none;
    background-color: transparent;
    color: ${Colors.Maroon100};
    &:hover {
      background-color: ${Colors.Tan5};
    }
    &:focus {
      background-color: ${Colors.Tan5};
    }
    &:active {
      background-color: ${Colors.Tan30};
    }
  `,
};

const buttonSizes = {
  small: css`
    font-size: ${CoreFontSize.s14};
    line-height: 16px;
    padding: 8px 12px;
  `,
  default: css`
    font-size: ${CoreFontSize.s16};
    line-height: 20px;
    padding: 8px 12px;
  `,
  large: css`
    font-size: ${CoreFontSize.s18};
    line-height: 22px;
    padding: 9px 12px;
  `,
};

const StyledButton = styled.button<ButtonProps>`
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  font-family: ${({theme}) => getMainFont(theme)};
  font-weight: ${CoreFontWeight.Medium};
  outline: none;
  width: auto;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;

  &:active {
    transition-duration: 0s;
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px ${Colors.White0},
      0px 0px 0px 4px ${Colors.Maroon100};
  }

  ${({variant = 'primary', danger = false}) =>
    danger ? dangerButtonColors[variant] : buttonColors[variant]};
  ${({size = 'default'}) => buttonSizes[size]};
  ${space};

  ${({disabled}) =>
    disabled &&
    `
    color: ${Colors.Gray40};
    cursor: not-allowed;
    &:hover {
      /* Override styles so nothing happens on hover */
      background-color: initial;
    }
  `}
  ${({disabled, variant}) =>
    (disabled &&
      variant === 'primary' &&
      `
    background-color: ${Colors.Tan5};
    &:hover {
      /* Override styles so nothing happens on hover */
      background-color: ${Colors.Tan5};
    }
  `) ||
    (disabled &&
      variant === 'secondary' &&
      `
    color: ${Colors.Gray40};
    border-color: ${Colors.Tan30};
    &:hover {
        /* Override styles so nothing happens on hover */
        color: ${Colors.Gray40};
        border-color: ${Colors.Tan30};
      }
  `)};

  ${({disabled, danger, variant = 'primary'}) =>
    (disabled &&
      danger &&
      variant === 'primary' &&
      `
    color: ${Colors.White0};
    background-color: ${Colors.Red10};
    cursor: not-allowed;
    &:hover {
      /* Override styles so nothing happens on hover */
      background-color: initial;
    }
  `) ||
    (disabled &&
      danger &&
      variant === 'secondary' &&
      `
    color: ${Colors.Red50};
    background-color: ${Colors.White0};
    border-color: ${Colors.Red50};
    cursor: not-allowed;
    &:hover {
      /* Override styles so nothing happens on hover */
      color: ${Colors.Red50};
      border-color: ${Colors.Red50};
    }
  `)}

  ${({isLoading}) =>
    isLoading &&
    `
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

const HideText = styled.div`
  opacity: 0;
`;

export const Button: React.FC<ButtonProps> = (props) => {
  if (props.isLoading) {
    const {children, ...otherProps} = props;
    return (
      <StyledButton {...otherProps}>
        <SpinnerLoader
          style={{position: 'absolute'}}
          size={24}
          color={
            otherProps.variant === 'primary' ? Colors.Maroon20 : Colors.Maroon90
          }
        />
        <HideText>{children}</HideText>
      </StyledButton>
    );
  }

  return <StyledButton {...props} />;
};
