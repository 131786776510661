import React, {Component, Fragment} from 'react';
import debounce from 'lodash.debounce';
import ky from 'ky-universal';
import {getHistory} from '../client/history';
import {FieldContainer} from './TextInput.jsx';
import {InnerWrapper} from './layout/index.jsx';
import Header from './Header.jsx';
import {EmailLogin} from './EmailLogin.jsx';
import Button from './Button.jsx';
import {
  Link,
  Input,
  Body05,
  Heading02,
  Flex
} from '@robinpowered/design-system';

/**
 * Selecting the organization to log into.
 */
export default class OrgSelect extends Component {
  constructor (props) {
    super(props);

    this.state = {
      emailLogin: true,
      org: null,
      loading: false,
      error: false
    };

    this.delayedCallback = debounce((orgSlug) => {
      this.setState({loading: true});
      ky.get(`/api/organizations/${orgSlug}`).json()
        .then(({data}) => this.setState({error: false, org: data}))
        .catch((err) => {
          window.Sentry && window.Sentry.captureException(err);

          this.setState({error: true, org: null});
        })
        .finally(() => this.setState({loading: false}));
    }, 300);
  }

  getOrganization (event) {
    event.persist();
    this.delayedCallback(event.target.value);
  }

  onRedirect () {
    getHistory().push(
      // `this.props.location.search` holds all of the query params.
      `/${this.state.org.slug}/login${this.props.location.search}`,
      {org: this.state.org}
    );
  }

  showEmailLoginScreen = (state) => {
    this.setState({
      emailLogin: state
    });
  };

  render() {
    return this.state.emailLogin ? (
      <EmailLogin email={''} props={this.props} showEmailLoginScreen={this.showEmailLoginScreen} />
    ) : (
      <Fragment>
        <Header showSignUp={true} />

        <InnerWrapper>
          <div>
            <Heading02>Enter your organization url</Heading02>

            <form>
              <FieldContainer>
                {/* Validate org input with debounce */}
                <Input type="text" id="org" name="org"
                  placeholder="organization-domain"
                  autoFocus required
                  onChange={this.getOrganization.bind(this)}
                />
              </FieldContainer>
              <FieldContainer style={{marginBottom: '0'}}>
                <Button
                  primary
                  type="submit"
                  style={{width: '100%'}}
                  disabled={this.state.error || !this.state.org}
                  onClick={() => this.onRedirect()}
                >
                  <span>Next</span>
                  {/* Add some type of loader */}
                </Button>
              </FieldContainer>
              <Flex
                display="flex"
                flexDirection="row"
                alignItems="baseline"
                justifyContent="center"
                mt="32px"
              >
                <Body05 marginRight="4px">Want to use your email?</Body05>

                <Link
                  style={{fontSize: '16px'}}
                  onClick={() => this.showEmailLoginScreen(true)}
                  accessible="true"
                >
                  Back to email login
                </Link>
              </Flex>
            </form>
          </div>
        </InnerWrapper>
      </Fragment>
    );
  }
}
