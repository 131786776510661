/* eslint-disable max-len */
import React from 'react';

export const MicrosoftLogo = props => (
  <svg width={16} height={16} viewBox="0 0 16 16"
    fill="none" {...props}>
    <path
      d="M9.592 0c-.02 0-8.576 3.21-8.588 3.222C1 3.226 1 5.391 1.001 8.032l.005 4.801 1.066-.417c.587-.23 1.241-.485 1.454-.57l.386-.151V7.786c0-2.696.006-3.912.02-3.92.031-.02 5.641-1.357 5.656-1.348.018.01.018 11.486 0 11.496a482.35 482.35 0 01-4.254-.576 805.316 805.316 0 00-4.252-.58c-.007.003 1.893.71 4.222 1.571 2.33.862 4.255 1.568 4.28 1.57.024 0 1.106-.293 2.404-.652l2.36-.653-.005-6.665-.004-6.665-2.365-.682C10.674.307 9.602 0 9.592 0z"
      fill="#EB3C00"
    />
  </svg>
);

export const GoogleLogo = props => (
  <svg width={16} height={16} viewBox="0 0 16 16"
    fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.68 8.182c0-.567-.05-1.113-.146-1.637H8V9.64h4.306a3.68 3.68 0 01-1.597 2.415v2.007h2.585c1.513-1.393 2.386-3.444 2.386-5.88z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16c2.16 0 3.97-.716 5.294-1.938l-2.585-2.007c-.716.48-1.633.763-2.709.763-2.084 0-3.847-1.407-4.476-3.298H.85v2.073A7.997 7.997 0 008 16z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.524 9.52c-.16-.48-.251-.993-.251-1.52s.09-1.04.25-1.52V4.407H.852A7.997 7.997 0 000 8c0 1.29.31 2.513.85 3.593L3.525 9.52z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.182c1.175 0 2.23.403 3.058 1.196l2.295-2.294C11.967.793 10.156 0 8 0A7.997 7.997 0 00.85 4.407L3.525 6.48C4.153 4.59 5.916 3.182 8 3.182z"
      fill="#EA4335"
    />
  </svg>
);
