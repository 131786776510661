import env from '../utils/universalEnv';

export const google = {
  client_id: env.GOOGLE_CLIENT_ID,
  authorization: 'https://accounts.google.com/o/oauth2/auth',
  scopes: {
    request: [
      'openid',
      'profile',
      'email',
      'https://www.googleapis.com/auth/calendar'
    ]
  }
};

export const office365 = {
  client_id: env.OFFICE365_CLIENT_ID,
  resource: 'https://outlook.office365.com',
  authorization: 'https://login.windows.net/common/oauth2/authorize'
};

export const office365Graph = {
  client_id: env.OFFICE365_GRAPH_CLIENT_ID,
  authorization: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  scopes: {
    request: [
      'https://graph.microsoft.com/Contacts.Read.Shared',
      'https://graph.microsoft.com/Calendars.ReadWrite.Shared',
      'https://graph.microsoft.com/User.ReadBasic.All',
      'https://graph.microsoft.com/User.Read'
    ]
  }
};
