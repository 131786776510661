import React, {FunctionComponent, PropsWithChildren} from 'react';
import {ThemeProvider, Global, css} from '@emotion/react';

import theme from '../theme';
import {fontDefinition} from '../Typography/fonts';

export const RKTheme: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          ${fontDefinition};
          * {
            box-sizing: border-box;
          }
        `}
      />
      {children}
    </ThemeProvider>
  );
};
