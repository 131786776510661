import styled from '@emotion/styled';
import {State} from './colors';
import spacing from './spacing';
import {Link} from 'react-router-dom';
import {BrownFontStack, Colors} from '@robinpowered/design-system';

const MainFontStack = BrownFontStack;

const Size = {
  Large: 22,
  Pronounced: 18,
  Default: 16,
  Small: 14,
  Micro: 12
};

const Weight = {
  Normal: 400,
  Medium: 500,
  Bold: 700
};

const SmallText = styled.div(({theme}) => ({
  color: Colors.Gray100,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Small
}));

const Text = styled.div(({theme}) => ({
  color: Colors.Gray100,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Default
}));

const DangerText = styled(Text)({
  color: State.Destructive,
  fontSize: '1em'
});

const SuccessText = styled(Text)({
  color: State.Success,
  fontSize: '1em'
});

const WhisperText = styled.div(({theme}) => ({
  color: Colors.Gray80,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Micro
}));

const Title = styled.div(({theme}) => ({
  color: Colors.Gray110,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Default,
  fontWeight: Weight.Medium,
  marginBottom: spacing(0.5),
  lineHeight: '1.5'
}));

const LoudTitle = styled.div(({theme}) => ({
  color: Colors.Gray110,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Large,
  marginBottom: spacing(0.5),
  fontWeight: Weight.Medium
}));

const Heading = styled.div(({theme}) => ({
  color: Colors.Gray110,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Large,
  lineHeight: '22px',
  letterSpacing: '0.01em',
  marginBottom: spacing(0.5),
  fontWeight: Weight.Medium,
  textAlign: 'center'
}));

const SubHeading = styled.h2(({theme}) => ({
  color: Colors.Gray90,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Small,
  marginTop: "8px",
  lineHeight: "22px",
  letterSpacing: "0.01em",
  marginBottom: spacing(0.5),
  fontWeight: Weight.Medium,
  textAlign: "center",
}));

const SoftTitle = styled.div(({theme}) => ({
  color: Colors.Gray100,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Small,
  fontWeight: Weight.Medium
}));

const QuietText = styled.div(({theme}) => ({
  color: Colors.Gray80,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Small,
  marginBottom: spacing(1)
}));

const BodyText = styled.label(({theme}) => ({
  color: Colors.Gray100,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontWeight: 500,
  fontFeatureSettings: 'ss02',
  fontSize: '16px',
  marginRight: '4px',
  display: 'flex'
}));

const Label = styled.label(({theme}) => ({
  color: Colors.Gray100,
  fontFamily: theme?.fonts?.main ?? MainFontStack,
  fontSize: Size.Small,
  fontWeight: Weight.Normal,
  marginBottom: spacing(0.5)
}));

const StyledLink = styled(Link)`
  font-family: ${props => props.theme?.fonts?.main ?? MainFontStack};

  font-size: ${Size.Default};
  display: block;
  color: ${Colors.Maroon100};
  font-weight: 500;
  text-decoration: unset;
  

  &:hover {
    color: ${Colors.Maroon100};
  }
`;

export {
  DangerText,
  Label,
  MainFontStack,
  LoudTitle,
  QuietText,
  Size,
  SoftTitle,
  SuccessText,
  Text,
  SmallText,
  Title,
  Weight,
  WhisperText,
  Heading,
  BodyText,
  StyledLink,
  SubHeading
};
